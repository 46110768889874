import React, { useEffect, useState } from "react";
import warning from "../../front-end-global-components/assets/warning_icon.svg";
import wait from "../../assets/icons/waitingIcon.svg";
import Menu from "../../components/Menu/Menu";
import "./Root.css";
import { connect } from "react-redux";
import Button from "../../front-end-global-components/components/Button/Button.js";
import Notification from "../../Screens/Notification/Notification";
import { isValidObject } from "../../utils/validators";
import Primary from "../Primary/Primary";
import Secondary from "../../Screens/Secondary/Secondary";
import Tertiary from "../Tertiary/Tertiary";
import ConditionalRender from "../../front-end-global-components/components/ConditionalRender/ConditionalRender";
import placeholderImage from "../../assets/images/Placeholder_image_clinic.png";
import nintoClinicLogo from "../../assets/images/logo/ninto-clinic-logo.png";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import healthMinistryLogo from "../../assets/images/logo/health_ministry_logo.svg";
import step1 from "../../assets/images/ABDM_Registration_Steps/step1.svg";
import step2 from "../../assets/images/ABDM_Registration_Steps/step2.svg";
import step3 from "../../assets/images/ABDM_Registration_Steps/step3.svg";
import step4 from "../../assets/images/ABDM_Registration_Steps/step4.svg";
import Modal from "../../front-end-global-components/components/Modal/Modal";
import { logout } from "../../redux/authentication/actions";
import {
  showPrimary,
  showSecondary,
  showTertiary
} from "../../redux/navigation/actions";
import { setCurrentConnection } from "../../redux/connection/actions";
import { deleteDocument } from "../../redux/documents/actions";

function Root(props) {
  const [showLogoutModal, setShowLogoutModal] = useState(false);
  const [showDocumentDeleteModal, setDocumentDeleteModal] = useState(false);
  const [selectedDocumentData, setSelectedDocumentData] = useState(null);
  const [isRequiredDataPresent, setIsRequiredDataPresent] = useState(false);
  const [verificationStatus, setVerificationStatus] = useState(null);
  const [currentSlide, setCurrentSlide] = useState(0);
  // For checking secondary and tertiary component required data are present
  useEffect(() => {
    const selectedPatientData = () => {
      if (props.abdmPatients.selectedPatient) {
        return true;
      } else {
        return false;
      }
    };

    const selectedDoctorData = () => {
      if (props.doctorSearch.selectedDoctor) {
        return true;
      } else {
        return false;
      }
    };
    const selectedDocumentData = () => {
      if (props.documentSearch.selectedDocument) {
        return true;
      } else {
        return false;
      }
    };

    const accountInformationData = () => {
      if (props.navigation.primary === "accountInformation") {
        return true;
      } else {
        return false;
      }
    };

    const editPreviewFormData = () => {
      if (
        props.navigation.primary === "form" &&
        props.navigation.secondary === "createEditPreviewForm"
      ) {
        return true;
      } else {
        return false;
      }
    };

    const abhaInformationData = () => {
      if (props.navigation.primary === "abha") {
        return true;
      } else {
        return false;
      }
    };

    if (
      selectedPatientData() ||
      selectedDoctorData() ||
      selectedDocumentData() ||
      accountInformationData() ||
      editPreviewFormData() ||
      abhaInformationData()
    ) {
      return setIsRequiredDataPresent(true);
    } else {
      return setIsRequiredDataPresent(false);
    }
  }, [
    props.doctorSearch.selectedDoctor,
    props.documentSearch.selectedDocument,
    props.navigation.primary,
    props.navigation.secondary,
    props.abdmPatients.selectedPatient
  ]);
  // set selected document
  useEffect(() => {
    if (props.documentSearch.selectedDocument) {
      setSelectedDocumentData(
        props.history.documents[props.connection.currentConnection][
          props.documentSearch.selectedDocument
        ]
      );
    }
  }, [
    props.documentSearch.selectedDocument,
    props.history.documents,
    props.connection.currentConnection
  ]);

  function handleDocumentDelete() {
    if (props.documentSearch.selectedDocument) {
      props.deleteDocument();
      setDocumentDeleteModal(false);
    }
  }
  // set verification status
  useEffect(() => {
    if (
      typeof props.connection?.currentConnection !== "string" ||
      !isValidObject(props.connection.data)
    ) {
      return;
    }
    if (
      props.connection?.data &&
      props.connection?.currentConnection &&
      isValidObject(props.connection.data) &&
      Object.keys(
        props.connection.data[props.connection.currentConnection]
      ).includes("hfrId")
    ) {
      if (
        !Object.keys(
          props.connection.data[props.connection.currentConnection]
        ).includes("verified")
      ) {
        setVerificationStatus("notVerified");
        return;
      } else if (
        props.connection.data[props.connection.currentConnection].verified ===
        false
      ) {
        setVerificationStatus("failed");
        return;
      } else if (
        props.connection.data[props.connection.currentConnection].verified ===
        true
      ) {
        setVerificationStatus("verified");
        return;
      }
      return;
    } else {
      setVerificationStatus("hfrIdNotExists");
      return;
    }
  }, [props.connection?.data, props.connection?.currentConnection]);

  return (
    <>
      <ConditionalRender
        condition={verificationStatus === "verified"}
      >
        <>
          <div
            data-cy="home-screen"
            className="display-flex inherit-parent-height "
          >
            <div className="inherit-parent-height user-select-none z-index-1 side-bar-flex-basis">
              <Menu
                navigation={props.navigation}
                homeClick={() => {
                  props.showPrimary("home");
                }}
                requestClick={() => {
                  props.showPrimary("request");
                }}
                patientClick={() => {
                  props.showPrimary("patientSearch");
                }}
                staffClick={() => {
                  props.showPrimary("staff");
                }}
                abhaClick={() => {
                  props.showPrimary("abha");
                }}
                uploadHistoryClick={() => {
                  props.showPrimary("documentSearch");
                }}
                formClick={() => {
                  props.showPrimary("form");
                }}
                settingClick={() => {
                  props.showPrimary("accountInformation");
                }}
              />
            </div>
            <>
              {props.navigation.primary === "home" ? (
                <>
                  <div className="inherit-parent-width overflow-auto back inherit-parent-height workspace-flex-basis"></div>
                </>
              ) : (
                <div className={`${"workspace-flex-basis width-20-percent"} `}>
                  <div className="inherit-parent-width overflow-auto invisible-scrollbar inherit-parent-height workspace-flex-basis">
                    <div className=" inherit-parent-height flex-center-children-vertically display-flex-direction-row">
                      <>
                        {props.navigation.primary && (
                          <Primary
                            remoteConfig={props.remoteConfig}
                            logoutButtonClick={() => {
                              setShowLogoutModal(true);
                            }}
                          />
                        )}
                      </>
                      <>
                        {isRequiredDataPresent &&
                          props.navigation.primary &&
                          props.navigation.secondary && (
                            <Secondary
                              navigate={props.navigate}
                              remoteConfig={props.remoteConfig}
                              documentDeleteOnClick={() => {
                                setDocumentDeleteModal(true);
                              }}
                            />
                          )}
                      </>
                      <>
                        {isRequiredDataPresent &&
                          props.navigation.primary &&
                          props.navigation.secondary &&
                          props.navigation.tertiary && (
                            <Tertiary remoteConfig={props.remoteConfig} />
                          )}
                      </>
                    </div>
                  </div>
                </div>
              )}
            </>

            <div className="inherit-parent-height z-index-1 side-bar-flex-basis">
              <Notification navigate={props.navigate} />
            </div>
          </div>

          {/* Delete Document Modal */}
          <Modal
            show={showDocumentDeleteModal}
            canIgnore={true}
            width="width-90-percent"
            height="fit-content"
            maxWidth="max-width-350px"
            // maxHeight="max-height-150px"
            onClose={() => {
              setDocumentDeleteModal(!showDocumentDeleteModal);
            }}
          >
            <div>
              <div className="flex-justify-content-center text-align-center font-color-secondary font-size-large font-family-gilroy-bold  padding-top-large padding-left-large padding-right-large padding-bottom-large ">
                Remove record?
              </div>
              <div className="flex-justify-content-center text-align-center font-color-secondary font-size-small font-family-gilroy-medium padding-left-large padding-right-large padding-bottom-large ">
                You are about to remove a record <br /> from &nbsp;
                {isValidObject(selectedDocumentData) &&
                selectedDocumentData?.patient?.name
                  ? selectedDocumentData?.patient?.name
                  : selectedDocumentData?.patient?.healthId}
                . You can not undo this change.
              </div>
              <div className="display-flex padding-left-large margin-bottom-larger">
                <div className="inherit-parent-width font-family-gilroy-bold ">
                  <Button
                    type="submit"
                    boxShadow={false}
                    data-cy="upload-history-modal-remove-button"
                    className="max-width-150px max-height-40px flex-place-children-page-center"
                    text="Remove"
                    variant="danger"
                    onClick={() => {
                      handleDocumentDelete();
                    }}
                  />
                </div>
                <div className="inherit-parent-width font-family-gilroy-medium ">
                  <Button
                    type="submit"
                    boxShadow={false}
                    className="max-width-150px max-height-40px flex-place-children-page-center"
                    text="Back"
                    variant="secondary"
                    onClick={() => {
                      setDocumentDeleteModal(false);
                    }}
                  />
                </div>
              </div>
            </div>
          </Modal>
        </>
      </ConditionalRender>
      <ConditionalRender
        condition={verificationStatus !== "verified"}
      >
        <Verification
          navigate={props.navigate}
          status={verificationStatus}
          currentSlide={currentSlide}
          setCurrentSlide={setCurrentSlide}
          showHomeButton={
            isValidObject(props.connection.data) &&
            Object.keys(props.connection.data).length > 1
              ? true
              : false
          }
          tryAgainOnClick={() => {
            props.navigate("/create");
          }}
          logOnClick={() => {
            setShowLogoutModal(true);
          }}
          homeOnClick={() => {
            props.setCurrentConnection(
              props.connection.data[
                Object.values(props.connection.data).find(
                  (data) =>
                    typeof data.hfrId === "string" && data.verified === true
                )?.connectionId
              ]?.connectionId
            );
          }}
          showGoBack={
            props.connection.data &&
            props.connection.data[
              Object.values(props.connection.data)?.find(
                (data) => typeof data.hfrId === "string"
              )?.connectionId
            ]?.connectionId
              ? true
              : false
          }
          goBackOnclick={() => {
            props.setCurrentConnection(
              props.connection.data[
                Object.values(props.connection.data).find(
                  (data) => typeof data.hfrId === "string"
                )?.connectionId
              ]?.connectionId
            );
          }}
        />
      </ConditionalRender>
      {/* Logout Modal */}
      <Modal
        show={showLogoutModal}
        canIgnore={true}
        width="width-90-percent"
        height="height-90-percent"
        maxWidth="max-width-350px"
        maxHeight="max-height-120px"
        onClose={() => {
          setShowLogoutModal(!showLogoutModal);
        }}
      >
        <div>
          <div className="flex-justify-content-center font-color-secondary font-size-medium font-family-gilroy-bold padding-top-large padding-bottom-large ">
            Are you sure you want to logout?
          </div>
          <div className="display-flex padding-left-large margin-bottom-larger">
            <div className="inherit-parent-width font-family-gilroy-bold margin-bottom-larger">
              <Button
                type="submit"
                data-cy="Modal-Logout-button"
                boxShadow={false}
                className="max-width-150px max-height-50px"
                text="Logout"
                variant="danger"
                onClick={() => props.logout()}
              />
            </div>
            <div className="inherit-parent-width font-family-gilroy-medium margin-bottom-larger">
              <Button
                type="submit"
                boxShadow={false}
                className="max-width-150px max-height-50px"
                text="Cancel"
                variant="secondary"
                onClick={() => {
                  setShowLogoutModal(false);
                }}
              />
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}
const mapStateToProps = function (state) {
  return {
    auth: state.auth,
    connection: state.connection,
    history: state.history,
    doctorSearch: state.doctorSearch,
    documentSearch: state.documentSearch,
    profile: state.profile,
    navigation: state.navigation,
    abdmPatients: state.abdmPatients
  };
};

const mapDispatchToProps = function () {
  return {
    logout: () => logout(),
    setCurrentConnection: (data) => setCurrentConnection(data),
    showPrimary: (data) => showPrimary(data),
    showSecondary: (data) => showSecondary(data),
    showTertiary: (data) => showTertiary(data),
    deleteDocument: () => deleteDocument()
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Root);

function Verification(props) {
  return (
    <>
      <div className="grey-background-image" data-cy="login-screen">
        <div data-cy="background-image">
          {props.status !== "hfrIdNotExists" && (
            <img
              draggable="false"
              data-cy="placeholder-image"
              className="icon-img"
              src={placeholderImage}
              alt="img"
            />
          )}
          {props.status === "hfrIdNotExists" && (
            <ABDMRegisterCarousel
              currentSlide={props.currentSlide}
              setCurrentSlide={props.setCurrentSlide}
            />
          )}
        </div>
        <div className="flex-center-children-horizontally flex-center-children-vertically box-shadow-default inherit-parent-height width-30percent position-fixed  background-white ">
          <div className="inherit-parent-width max-width-300px flex-place-children-page-center">
            <img
              draggable="false"
              className="width-155px margin-bottom-larger padding-bottom-larger"
              src={nintoClinicLogo}
              alt="login-logo"
            />
            {props.status === "failed" && (
              <>
                <div
                  data-cy="failed-fallBack"
                  className=" flex-center-children-horizontally "
                >
                  <img
                    className=" height-width-32px margin-bottom-larger"
                    src={warning}
                    alt="waiting"
                  />
                </div>
                <div className="text-align-center font-size-large font-family-gilroy-medium padding-horizontal-large">
                  Verification failed
                </div>
                <div className=" text-align-center font-size-large padding-vertical-large">
                  Please try again or contact our technical team
                </div>
                <Button
                  type="button"
                  className="margin-bottom-large"
                  text="Try again"
                  boxShadow={false}
                  onClick={props.tryAgainOnClick}
                />
                {props.showHomeButton && (
                  <Button
                    type="button"
                    variant="secondary"
                    className="margin-bottom-large"
                    text="Home"
                    boxShadow={false}
                    onClick={props.homeOnClick}
                  />
                )}
                <div className="text-align-center  padding-vertical-large">
                  <span className=" font-color-primary">Contact</span> our
                  experts for help.
                </div>
              </>
            )}
            {props.status === "notVerified" && (
              <>
                <div
                  data-cy="verified-fallBack"
                  className=" flex-center-children-horizontally"
                >
                  <img
                    className=" height-width-32px margin-bottom-larger"
                    src={wait}
                    alt="waiting"
                  />
                </div>
                <div className="text-align-center font-size-large font-family-gilroy-medium padding-horizontal-large">
                  Setup complete!
                </div>
                <div className=" text-align-center font-size-large padding-vertical-large">
                  Please wait while our team verifies the information provide.
                </div>
                {props.showHomeButton ? (
                  <Button
                    type="button"
                    className="margin-bottom-large"
                    text="Home"
                    boxShadow={false}
                    onClick={props.homeOnClick}
                  />
                ) : (
                  <div className="text-align-center padding-vertical-large">
                    You can{" "}
                    <span
                      className=" font-color-primary"
                      onClick={props.logOnClick}
                    >
                      Log in
                    </span>{" "}
                    once your account is verified
                  </div>
                )}
              </>
            )}

            {props.status === "hfrIdNotExists" && (
              <>
                <div
                  data-cy="hfrId-fallBack"
                  className="font-family-gilroy-regular font-size-medium padding-bottom-larger padding-top-larger text-align-center"
                >
                  Sign up with ABDM and select ninto as your healthcare partner
                </div>

                <Button
                  data-cy="redirect-to-ABDM-button"
                  className="max-width-300px margin-bottom-large"
                  text="Sign up with ABDM"
                  // loading={props.auth.loading}
                  onClick={() => {
                    window.open("https://facilitysbx.abdm.gov.in/", "_blank");
                  }}
                  boxShadow={false}
                />

                {props.showGoBack && (
                  <div
                    className="font-color-primary"
                    onClick={props.goBackOnclick}
                  >
                    Go Back
                  </div>
                )}

                <div className="display-flex flex-justify-content-center text-align-center font-size-medium font-family-gilroy-regular margin-top-larger">
                  <p>
                    Having a trouble or require assistance signing up with ABDM?
                    <div
                      onClick={() => {
                        props.navigate("/login");
                      }}
                    >
                      Schedule a call
                    </div>
                    with our experts for help
                  </p>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

const ABDMRegisterCarousel = (props) => {
  return (
    <div className="position-absolute-top50-left60 width-60-percentage border-radius-32px gradient-top-to-bottom">
      <div className="flex-justify-content-space-between padding-top-larger padding-left-larger padding-right-larger">
        <p className="font-family-gilroy-medium font-size-larger font-color-tertiary ">
          How to register your facility with ABDM
        </p>
        <img
          draggable="false"
          className=""
          src={healthMinistryLogo}
          alt="img"
        />
      </div>
      <Carousel
        className="margin-top-medium height-fit-content"
        showStatus={false}
        showArrows={false}
        showThumbs={false}
        showIndicators={false}
        selectedItem={props.currentSlide}
        onChange={(index) => {
          props.setCurrentSlide(index);
        }}
        transitionTime={0}
        swipeScrollTolerance={100}
        preventMovementUntilSwipeScrollTolerance={true}
        swipeable={true}
        stopOnHover={false}
      >
        <div className="height-444px">
          <img
            src={step1}
            alt="Step 1"
            className="width-40-percentage inherit-parent-height absolute-center-self-horizontally"
          />
        </div>
        <div className="height-444px">
          <img
            src={step2}
            alt="Step 2"
            className="width-40-percentage inherit-parent-height absolute-center-self-horizontally"
          />
        </div>
        <div className="height-444px">
          <img
            src={step3}
            alt="Step 3"
            className="width-50-percentage inherit-parent-height absolute-center-self-horizontally"
          />
        </div>
        <div className="height-444px">
          <img
            src={step4}
            alt="Step 4"
            className="width-50-percentage inherit-parent-height absolute-center-self-horizontally"
          />
        </div>
      </Carousel>
      <SliderControl
        currentSlide={props.currentSlide}
        setCurrentSlide={props.setCurrentSlide}
      />
    </div>
  );
};

const SliderControl = (props) => {
  return (
    <div className="flex-center-children-vertically margin-top-40px inherit-parent-width inherit-parent-width flex-justify-content-space-between">
      <div
        className="flex-center-children-vertically width-20-percentage user-select-none cursor-pointer flex-direction-column"
        onClick={() => {
          props.setCurrentSlide(0);
        }}
        data-cy="videoSlider-0"
      >
        <div
          className={`${
            props.currentSlide === 0
              ? "background-color-primary"
              : "background-white"
          } height-7px inherit-parent-width border-radius-default margin-top-small`}
        />
      </div>

      <div
        className="flex-center-children-vertically width-20-percentage user-select-none cursor-pointer flex-direction-column"
        onClick={() => {
          props.setCurrentSlide(1);
        }}
        data-cy="videoSlider-1"
      >
        <div
          className={`${
            props.currentSlide === 1
              ? "background-color-primary"
              : "background-white"
          } height-7px inherit-parent-width border-radius-default margin-top-small`}
        />
      </div>

      <div
        className="flex-center-children-vertically width-20-percentage user-select-none cursor-pointer flex-direction-column"
        onClick={() => {
          props.setCurrentSlide(2);
        }}
        data-cy="videoSlider-2"
      >
        <div
          className={`${
            props.currentSlide === 2
              ? "background-color-primary"
              : "background-white"
          } height-7px inherit-parent-width border-radius-default margin-top-small`}
        />
      </div>

      <div
        className="flex-center-children-vertically width-20-percentage user-select-none cursor-pointer flex-direction-column"
        onClick={() => {
          props.setCurrentSlide(3);
        }}
        data-cy="videoSlider-3"
      >
        <div
          className={`${
            props.currentSlide === 3
              ? "background-color-primary"
              : "background-white"
          } height-7px inherit-parent-width border-radius-default margin-top-small`}
        />
      </div>
    </div>
  );
};
