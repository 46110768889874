import React, { useEffect, useState } from "react";
import "./index.css";
import LogRocket from "logrocket";
import { Route, useNavigate, Navigate, Routes } from "react-router-dom";
import Root from "./Screens/Root/Root";
import Login from "./Screens/Login/Login";
import {
  useNotificationListener,
  // useRequestsSubscriber,
  // createDocuments,
  useDocumentListener,
  useProfileListener,
  useConnectionsListener
} from "./services/database";
import { connect } from "react-redux";
import { useAuthSubscriber } from "./services/authentication";
import SnackBar from "./front-end-global-components/components/SnackBar/SnackBar";
import SignUp from "./Screens/SignUp/SignUp";
// eslint-disable-next-line
// import { appCheck } from "./services/appCheck";
// eslint-disable-next-line
// import perf from "./services/performance";
import Create from "./Screens/Create/Create";
import { isValidObject } from "./utils/validators";
import Modal from "./front-end-global-components/components/Modal/Modal";
import Button from "./front-end-global-components/components/Button/Button";
import InputBox from "./front-end-global-components/components/InputBox/InputBox";
import { editProfile } from "./redux/profile/actions";
import { logrocketInit } from "./utils/constants";
import { useRemoteConfig } from "./services/remoteConfig";
import { logUserData } from "./services/analytics";
// import callBackImage from "./assets/icons/permissionDeniedFallBack.svg";
// import { logout } from "./redux/authentication/actions";
import { onMessage } from "firebase/messaging";
import { messaging } from "./services/firebase";

function App(props) {
  const [status, setStatus] = useState(null);
  const [showAddUserNameModal, setShowAddUserNameModal] = useState(false);
  const [addUserNameFormData, setAddUserNameFormData] = useState({
    userName: ""
  });
  const [addUserNameFormDataIsValid, setAddUserNameFormDataIsValid] = useState({
    userName: true
  });
  const [isAuthed, setIsAuthed] = useState(false);
  const [remoteConfig, setRemoteConfig] = useState(null);
  const navigate = useNavigate();

  //auth listener custom hook
  useAuthSubscriber((isAuthed) => {
    setIsAuthed(isAuthed);
  }, isAuthed);

  // listener for receiving silent notification for
  onMessage(messaging, (payload) => {
    // setNotify(payload.data.value);
    console.log(payload);
  });

  // listener for receiving background silent notification for
  // useEffect(() => {
  //   const channel = new BroadcastChannel("silent-notification-channel");
  //   const handleMessage = (event) => {
  //     const payload = event.data;
  //     setNotify(payload.data.value);
  //   };

  //   // Add the event listener for the broadcast channel
  //   channel.addEventListener("message", handleMessage);

  //   // Clean up the event listener on component unmount
  //   return () => {
  //     channel.removeEventListener("message", handleMessage);
  //   };
  // }, []);

  // remote config listener custom hook
  useRemoteConfig((remoteConfig) => {
    setRemoteConfig(remoteConfig);
  }, isAuthed);

  useProfileListener({
    uid: props.auth.data.uid,
    phoneNumber: props.auth.data.phoneNumber,
    isAuthed: isAuthed
  });

  useConnectionsListener({
    uid: props.profile.data && Object.keys(props.profile.data)[0],
    phoneNumber: props.auth.data.phoneNumber,
    isAuthed: isAuthed
  });

  useDocumentListener({
    clinicId:
      props.connection?.data &&
      Object.values(props.connection.data)
        .filter(
          (data) => typeof data.hfrId === "string" && data.verified === true
        )
        .map((data) => data.connectionId),
    isAuthed: isAuthed
  });

  useNotificationListener({
    profileId: isValidObject(props.profile?.data) && Object.keys(props.profile?.data)[0],
    isAuthed: isAuthed,
    phoneNumber: isValidObject(props.profile?.data) ? props.profile.data.phoneNumber : null
  });

  //for log rocket init
  useEffect(() => {
    if (
      process.env.NODE_ENV === "production" &&
      window.location.hostname !== "localhost"
    ) {
      const release =
        typeof process.env.REACT_APP_BUILD_NUM === "string"
          ? { release: process.env.REACT_APP_BUILD_NUM }
          : {};
      if (logrocketInit.key) {
        LogRocket.init(logrocketInit.key, release);
      }
    }
  }, []);
  //for snack bar messages
  useEffect(() => {
    if (props.status.message) {
      setStatus(props.status.message);
    } else {
      setStatus(null);
    }
  }, [props.status]);

  useEffect(() => {
    if (isValidObject(props.profile.data) && !isValidObject(props.connection.data)) {
      navigate("/create");
    }
    // eslint-disable-next-line
  }, [props.connection.data]);

  //for add user name pop up
  useEffect(() => {
    if (!props.auth.data.uid) {
      return;
    }
    if (!isValidObject(props.profile.data)) {
      return;
    }
    if (
      isAuthed &&
      props.auth.data.uid !== null &&
      (props.profile.data[Object.keys(props.profile.data)[0]]?.fullName ===
        undefined ||
        props.profile.data[Object.keys(props.profile.data)[0]]?.fullName ===
          null)
    ) {
      setShowAddUserNameModal(true);
    } else {
      setShowAddUserNameModal(false);
    }
  }, [props.profile.data, props.auth.data, isAuthed]);

  //useEffect for relaying user data to analytics
  useEffect(() => {
    if (props.auth.data.phoneNumber && props.auth.data.uid) {
      logUserData(props.auth.data.phoneNumber, props.auth.data.uid);
    }
  }, [props.auth.data.phoneNumber, props.auth.data.uid]);
  return (
    <>
      <Routes>
        <Route
          path="/login"
          element={
            <PublicRoute
              uid={props.auth.data.uid}
              profileData={props.profile.data}
            >
              <Login navigate={navigate} />
            </PublicRoute>
          }
        />

        <Route
          path="/signup"
          element={
            <PublicRoute
              uid={props.auth.data.uid}
              profileData={props.profile.data}
            >
              <SignUp navigate={navigate} />
            </PublicRoute>
          }
        />

        <Route
          path="/"
          element={
            <ProtectedRoute
              uid={props.auth.data.uid}
              profileData={props.profile.data}
              remoteConfig={remoteConfig}
              connectionData={props.connection.data}
            >
              <Root navigate={navigate} remoteConfig={remoteConfig} />
            </ProtectedRoute>
          }
        />
        <Route
          path="/create"
          element={
            <ProtectedRoute
              uid={props.auth.data.uid}
              profileData={props.profile.data}
              remoteConfig={remoteConfig}
            >
              <Create navigate={navigate} />
            </ProtectedRoute>
          }
        />

        {/* <Route path="/test" component={Test} /> */}
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
      {/* ADD USERNAME MODAL */}
      <Modal
        show={showAddUserNameModal}
        canIgnore={false}
        onClose={() => {
          setShowAddUserNameModal(!showAddUserNameModal);
        }}
        width="inherit-parent-width"
        maxWidth="max-width-500px"
        background="false"
        boxShadow="false"
        borderRadius="false"
        height="height-fit-to-content"
      >
        <div
          className={
            "background-white padding-larger border-radius-default box-shadow-default font-family-gilroy-regular font-color-secondary"
          }
        >
          <div className=" text-align-center font-family-gilroy-medium">
            Welcome! What is your name?
          </div>
          <form
            onChange={(event) => {
              setAddUserNameFormData({
                [event.target.name]: event.target.value
              });
            }}
            onSubmit={(event) => {
              event.preventDefault();
              props.editProfile({ data: addUserNameFormData });
            }}
          >
            <InputBox
              className="inherit-parent-width margin-top-larger"
              label="Your name"
              type="text"
              removeResponsive={true}
              name="userName"
              isValidInput={(value) => {
                setAddUserNameFormDataIsValid({
                  ...addUserNameFormDataIsValid,
                  userName: value
                });
              }}
              notes="Please enter name as per your official ID"
              value={addUserNameFormData.userName}
            />
            <Button
              boxShadow={false}
              disabled={
                !Object.values(addUserNameFormDataIsValid).every(
                  (data) => data === true
                )
              }
              className="margin-top-larger font-family-gilroy-medium"
              variant="primary"
              text="Continue"
              type="submit"
            />
          </form>
        </div>
      </Modal>
      <SnackBar
        message={status}
        status={props.status}
        type={props.status.code === null ? "success" : "error"}
      />
    </>
  );
}

const ProtectedRoute = ({ uid, children, profileData, remoteConfig }) => {
  if (uid === null || !isValidObject(profileData)) {
    return <Navigate to="/login" />;
  }

  // else if (remoteConfig?.experimental === false) {
  //   return <UnAuthorizedFallBack />;
  // } else if (remoteConfig === null) {
  //   return <div></div>;
  // }
  else {
    return children;
  }
};

const PublicRoute = ({ uid, children, profileData }) => {
  if (uid === null || !isValidObject(profileData)) {
    return children;
  } else {
    return <Navigate to="/" />;
  }
};

const mapStateToProps = function (state) {
  return {
    status: state.status,
    auth: state.auth,
    profile: state.profile,
    connection: state.connection
  };
};

const mapDispatchToProps = function () {
  return {
    editProfile: (data) => editProfile(data)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);

// const UnAuthorizedFallBack = () => {
//   return (
//     <main className="inherit-parent-height background-color-white inherit-parent-width flex-place-children-page-center">
//       <div>
//         <div className="padding-bottom-larger flex-center-children-horizontally">
//           <img
//             style={{ width: 239, height: 190 }}
//             draggable="false"
//             src={callBackImage}
//             alt="call back UI"
//           />
//         </div>
//         <div>
//           <div
//             style={{ fontSize: 24 }}
//             className="font-weight-normal font-family-gilroy-bold text-align-center font-color-secondary font-size-larger padding-bottom-large"
//           >
//             Access Denied !
//           </div>
//           <div className="text-align-center font-size-larger">
//             You are not authorized for further access.
//           </div>
//           <div className="text-align-center font-size-larger">
//             Please check back after sometime.
//           </div>
//         </div>
//         <div className=" text-align-center padding-top-larger">
//           <Button
//             type="submit"
//             data-cy="take-me-back-button"
//             className="inherit-parent-width max-width-150px"
//             text="Take me back"
//             onClick={() => {
//               logout();
//             }}
//           />
//         </div>
//       </div>
//     </main>
//   );
// };
