import React, { useState, useEffect } from "react";
import Button from "../../front-end-global-components/components/Button/Button";
import OTPInputBox from "../../front-end-global-components/components/OTPInputBox/OTPInputBox";
import InputBox from "../../front-end-global-components/components/InputBox/InputBox";
import CheckBox from "../../front-end-global-components/components/CheckBox/CheckBox";
import onBoarding from "../../assets/icons/onBoardTickIcon.svg";
import { setErrorStatus } from "../../redux/status/actions";

export default function AbhaLogin(props) {
  const [abhaLoginOtp, setAbhaLoginOtp] = useState("");
  const [counter, setCounter] = useState(null);
  const [running, setRunning] = useState(false);
  const [loginData, setLoginData] = useState({
    useAadhaarPhoneNumber: false,
    loginValue: ""
  });

  useEffect(() => {
    let counterTimer;
    if (counter && counter !== null && typeof counter === "number" && running) {
      counterTimer = setTimeout(() => {
        setCounter(counter - 1);
      }, 1000);
    } else {
      setCounter(null);
      setRunning(false);
      return () => clearTimeout(counterTimer);
    }
    // eslint-disable-next-line
  }, [counter]);

  function loginValueValidation() {
    if (loginData.loginValue.length >= 8) {
      props.sendAbhaOtp(loginData);
    } else if (
      loginData.loginValue.length === 14 ||
      loginData.loginValue.length === 17
    ) {
      if (
        loginData.loginValue.length === 14 &&
        /^\d+$/.test(loginData.loginValue) === true
      ) {
        const abhaNumberArray = loginData.loginValue.split("");
        abhaNumberArray.splice(2, 0, "-");
        abhaNumberArray.splice(7, 0, "-");
        abhaNumberArray.splice(12, 0, "-");
        setLoginData({
          ...loginData,
          loginValue: abhaNumberArray.toString().replaceAll(",", "")
        });
        props.sendAbhaOtp(loginData);
        setCounter(60);
        setRunning(true);
      }
      if (loginData.loginValue.includes("-") === true) {
        props.sendAbhaOtp(loginData);
      }
    } else {
      setErrorStatus({
        code: "custom",
        message: "Please enter Valid ABHA No./ABHA Address"
      });
    }
  }

  useEffect(() => {
    if (props.abha.sentAbhaOtp) {
      setCounter(60);
      setRunning(true);
    } else {
      setRunning(false);
    }
    if (props.abha.login && running) {
      setRunning(false);
    }
    // eslint-disable-next-line
  }, [props.abha.sentAbhaOtp, props.abha.login]);

  return (
    <>
      {props.abha.login === false ? (
        <div className="inherit-parent-height">
          <p className="text-align-center font-family-gilroy-medium margin-top-default">
            OTP will be sent to the{" "}
            {loginData.useAadhaarPhoneNumber === true && `Aaadhaar`} phone
            number linked to this ABHA No. / ABHA Address
          </p>
          <form
            onSubmit={(event) => {
              event.preventDefault();
              loginValueValidation();
            }}
            className=" padding-top-larger"
          >
            <InputBox
              removeResponsive={true}
              name="abhaNo_healthId"
              label="ABHA No. / Address"
              type="text"
              buttonTile={
                typeof counter === "number" && counter !== 0
                  ? `${
                      counter?.toString().length === 1 ? "0" : ""
                    }${counter?.toString()} seconds`
                  : typeof props.abha.transactionId === "string"
                  ? "Resend OTP"
                  : "Send OTP"
              }
              buttonTileId="aadhaar-button"
              buttonType="submit"
              suffixButtonDisabled={
                props.abha.loading ||
                (counter && typeof counter === "number" ? true : false)
              }
              onChange={(event) => {
                setLoginData({ ...loginData, loginValue: event.target.value });
              }}
              value={loginData.loginValue}
              required
            />
            <CheckBox
              className="inherit-parent-width margin-bottom-large max-width-300px border-radius-default padding-top-default padding-left-small padding-bottom-larger "
              label="Use Aadhaar phone number"
              name="useAadhaarPhoneNumber"
              onChange={(event) => {
                setLoginData({
                  ...loginData,
                  useAadhaarPhoneNumber: event.target.checked
                });
              }}
            />
          </form>

          <form
            className="inherit-parent-height"
            onSubmit={(event) => {
              event.preventDefault();
              props.verifyAbhaOtpAndLogin(abhaLoginOtp, loginData);
            }}
          >
            {props.abha.sentAbhaOtp === true && (
              <div className="padding-top-larger">
                <OTPInputBox
                  name="otp"
                  size="small"
                  className=" margin-top-default "
                  setValue={(value) => {
                    setAbhaLoginOtp(value);
                  }}
                  required
                />
              </div>
            )}

            <Button
              className="margin-top-larger"
              text="Login"
              type="submit"
              disabled={
                !(typeof abhaLoginOtp === "string" && abhaLoginOtp.length === 6)
              }
              loading={props.abha.loading}
            />
          </form>
        </div>
      ) : (
        <div className="flex-place-children-page-center inherit-parent-height">
          <img src={onBoarding} alt="success" />
          <div className="text-align-center padding-top-larger">
            Patient has been onboarded successfully
          </div>
          <Button
            className="margin-top-larger"
            text="Back"
            type="button"
            onClick={() => {
              props.resetStatus();
              setLoginData({
                useAadhaarPhoneNumber: false,
                loginValue: ""
              });
              setAbhaLoginOtp("");
            }}
            loading={props.abha.loading}
          />
        </div>
      )}
    </>
  );
}
