import React, { useEffect, useState } from "react";
import "../../design-system.css";
import pdfIcon from "../../assets/PdfIcon.svg";
import unsupportedIcon from "../../assets/Unsupported.svg";
import "./FHIRRenderer.css";
import RoundedProfilePicture from "../RoundedProfilePicture/RoundedProfilePicture";
import { dateAndTimeConverter } from "../../utils/constants";
import dropDownArrow from "../../assets/DropDownArrow.svg";
import PropTypes from "prop-types";

export default function FHIRRenderer(props) {
  // for testing purpose
  // useEffect(() => {
  //   console.log(props.data);
  //   // eslint-disable-next-line
  // }, []);

  const getComponents = (type, index, data) => {
    let value;
    switch (type) {
      case "Patient": {
        value = (
          <Patient
            key={index}
            data={getResourceData(props.data, data?.reference)}
          />
        );
        break;
      }

      case "Practitioner": {
        value = (
          <Practitioner
            key={index}
            data={getResourceData(props.data, data?.reference)}
          />
        );
        break;
      }
      case "Encounter": {
        value = (
          <Encounter
            key={index}
            data={getResourceData(props.data, data?.reference)}
          />
        );

        break;
      }
      case "Composition": {
        value = (
          <DateAndTime
            key={index}
            data={getResourceData(props.data, data?.reference)}
          />
        );
        break;
      }
      case "DocumentReference": {
        value = (
          <DocumentReference
            key={index}
            data={getResourceData(props.data, data?.reference)}
            documentOnClick={(document) => {
              props.documentOnClick(document);
            }}
          />
        );
        break;
      }
      case "Immunization": {
        value = (
          <Immunization
            key={index}
            value={props.data}
            data={getResourceData(props.data, data?.reference)}
          />
        );
        break;
      }
      case "Appointment": {
        value = (
          <Appointment
            key={index}
            data={getResourceData(props.data, data?.reference)}
          />
        );
        break;
      }
      case "CarePlan": {
        value = (
          <CarePlan
            key={index}
            data={getResourceData(props.data, data?.reference)}
          />
        );
        break;
      }
      case "Condition": {
        value = (
          <Condition
            key={index}
            data={getResourceData(props.data, data?.reference)}
          />
        );
        break;
      }
      case "AllergyIntolerance": {
        value = (
          <AllergyIntolerance
            key={index}
            data={getResourceData(props.data, data?.reference)}
          />
        );
        break;
      }
      case "Procedure": {
        value = (
          <Procedure
            key={index}
            value={props.data}
            data={getResourceData(props.data, data?.reference)}
          />
        );
        break;
      }
      case "Observation": {
        value = (
          <Observation
            key={index}
            value={props.data}
            data={getResourceData(props.data, data?.reference)}
          />
        );
        break;
      }
      case "DiagnosticReport": {
        value = (
          <DiagnosticReport
            key={index}
            value={props.data}
            data={getResourceData(props.data, data?.reference)}
          />
        );
        break;
      }
      case "Organization": {
        value = (
          <Organization
            key={index}
            data={getResourceData(props.data, data?.reference)}
          />
        );
        break;
      }
      case "MedicationRequest": {
        value = (
          <Medication
            key={index}
            value={props.data}
            data={getResourceData(props.data, data?.reference)}
          />
        );
        break;
      }
      default: {
        value = <div key={index}></div>;
      }
    }
    return value;
  };

  const Accordion = (props) => {
    const [show, setShow] = useState(false);
    return (
      <>
        <div
          className={`display-flex flex-justify-content-space-between padding-large ${
            show ? "" : "border-bottom-1px"
          }`}
          onClick={() => {
            if (show === false) {
              setShow(true);
            } else {
              setShow(false);
            }
          }}
        >
          <div
            className={`${
              show ? "font-family-gilroy-medium " : ""
            } font-size-default`}
          >
            {props.data.title}
          </div>
          <DropDownButton
            dropDownButton-data-cy="title-dropDown"
            dropDownClick={() => {
              if (show === false) {
                setShow(true);
              } else {
                setShow(false);
              }
            }}
            showData={show}
          />
        </div>
        {show && (
          <div className="border-bottom-1px">
            {props.data.entry.map((data, index) => {
              const resourceType = data?.reference?.split("/")[0];
              return getComponents(resourceType, index, data);
            })}
            <BasicInfo />
          </div>
        )}
      </>
    );
  };

  const getClinicSrc = (type) => {
    let value;
    switch (type) {
      case "clinic":
        value =
          props.connection?.brands[
            props.connection.data[props.document?.clinicId]?.brandId
          ]?.downloadURL;
        break;

      default:
        value = null;
        break;
    }
    return value;
  };

  const searchResource = (value) => {
    return props?.data.entry.find(
      (data) => data.resource.resourceType === value
    )?.resource;
  };

  const BasicInfo = (props) => {
    const [data, setData] = useState({
      clinic: "",
      doctor: "",
      patient: ""
    });
    useEffect(() => {
      setData({
        clinic: searchResource("Organization"),
        doctor: searchResource("Practitioner"),
        patient: searchResource("Patient")
      });
    }, []);

    return (
      <div className="display-flex padding-top-default padding-bottom-default padding-left-large padding-right-large flex-justify-content-space-between adaptive-flex-direction">
        {data?.clinic?.name && (
          <div
            data-cy="Organization-card"
            className="padding-default display-flex"
          >
            <div className="font-family-gilroy-medium">Source &nbsp; </div>
            :&nbsp;
            {data?.clinic?.name}
          </div>
        )}
        {data?.patient?.name && (
          <div
            data-cy="Patient-card"
            className="padding-default display-medium display-flex"
          >
            <div className="font-family-gilroy-medium">Patient &nbsp; </div>
            :&nbsp;
            {(data?.patient?.name && data?.patient?.name[0].text) ?? ""}
          </div>
        )}
        {data?.doctor?.name && (
          <div
            data-cy="Practitioner-card"
            className="padding-default display-medium display-flex"
          >
            <div className=" font-family-gilroy-medium">Handled By &nbsp; </div>
            :&nbsp;
            {(data?.doctor?.name && data?.doctor?.name[0]?.prefix[0]) ?? ""}.
            {(data?.doctor?.name && data?.doctor?.name[0]?.text) ?? ""}{" "}
            {(data?.doctor?.name && data?.doctor?.name[0]?.suffix[0]) ?? ""}
          </div>
        )}
      </div>
    );
  };

  return (
    <div data-cy="FHIR-Component" className="inherit-parent-width">
      <div className="border-bottom-2px padding-top-default padding-bottom-larger">
        <div className=" display-flex flex-align-items-center flex-justify-content-space-between">
          <div className="flex-center-children-vertically font-family-gilroy-regular padding-left-larger padding-right-larger">
            <RoundedProfilePicture
              data-cy="Organization-profile-picture"
              size="medium"
              userType="corporate"
              removeResponsive={true}
              src={getClinicSrc(props.userType)}
            />
            <div className="padding-left-large">
              <div className="font-size-large padding-right-default text-capitalize">
                {props.document?.clinicName}
              </div>
              <div className=" font-size-small padding-top-small">
                {props.document?.locality ? props.document?.locality : ""}
              </div>
            </div>
          </div>
          <div>
            {` ${dateAndTimeConverter(
              +new Date(props.data.timestamp),
              "cardDate"
            )} ,
             ${dateAndTimeConverter(+new Date(props.data.timestamp), "Time")}`}
          </div>
        </div>
      </div>
      <div className="padding-default overflow-auto">
        {props.data?.entry[0]?.resource?.section?.map((data, index) => (
          <div
            className="margin-bottom-default padding-left-larger padding-right-larger"
            data-cy={`accordion-${data.title}-card`}
            key={index}
          >
            <Accordion data={data} />
          </div>
        ))}
      </div>
    </div>
  );
}

const Practitioner = (props) => {
  const name = props?.data?.name[0];
  return (
    <div className="padding-larger" data-cy="Practitioner-card">
      <div className="font-size-small text-uppercase">Practitioner</div>
      <div className=" flex-center-children-vertically flex-justify-content-space-between flex-direction-row">
        <div className="">
          <div className="font-size-large">
            {name?.prefix[0] ?? ""}.{name?.text ?? ""}
          </div>
          <div className="font-size-small padding-top-small">
            {name?.suffix[0] ?? ""}
          </div>
        </div>
        <div>
          <RoundedProfilePicture
            data-cy="Practitioner-profile-picture"
            className="margin-top-default"
            size={"medium"}
            // src={googleFit}
          />
        </div>
      </div>
    </div>
  );
};

const Patient = (props) => {
  const name = props?.data?.name[0];
  return (
    <div className="padding-larger" data-cy="Patient-card">
      <div className="font-size-small text-uppercase">Patient</div>
      <div className="flex-center-children-vertically flex-justify-content-space-between flex-direction-row">
        <div className="padding-top-small">
          <div className="font-size-large">{name?.text ?? ""}</div>
          <div className="font-size-small padding-top-default">
            {props.data?.id ?? ""}
          </div>
          <div className="font-size-small padding-top-small  text-transform-capitalize ">
            {props.data?.gender ?? ""}
          </div>
        </div>
        <div>
          <RoundedProfilePicture
            data-cy="Patient-profile-picture"
            className="margin-top-default"
            size={"medium"}
            // src={googleFit}
          />
        </div>
      </div>
    </div>
  );
};

const Encounter = (props) => {
  return (
    <div className="padding-larger" data-cy="Encounter-card">
      <div className="font-size-small text-uppercase">Encounter</div>
      <div className="flex-center-children-vertically flex-justify-content-space-between flex-direction-row">
        {props.data?.text?.div && (
          <div className="padding-top-small">{props.data?.text.div}</div>
        )}
        <div className="padding-top-small">
          <div className="font-size-large">
            {props.data?.class.display ?? ""}
          </div>
        </div>
        <div>
          <div
            data-cy="status-card"
            className="padding-top-small padding-bottom-small padding-right-large padding-left-large border-radius-default font-color-primary font-size-small text-capitalize font-color-light-green"
          >
            {props.data?.status ?? ""}
          </div>
        </div>
      </div>
    </div>
  );
};

const DateAndTime = (props) => {
  const dataAndTime = +new Date(props.data?.date);
  return (
    <div
      className="padding-larger flex-direction-row"
      data-cy="DateAndTime-card"
    >
      <div className=" width-50-percentage text-align-center">
        <div className="font-size-small text-uppercase">Date</div>
        <div className=" padding-top-default">
          {dateAndTimeConverter(dataAndTime, "secondDate")}
        </div>
      </div>
      <div className=" width-50-percentage text-align-center">
        <div className="font-size-small">Time</div>
        <div className=" padding-top-default">
          {dateAndTimeConverter(dataAndTime, "Time")}
        </div>
      </div>
    </div>
  );
};

const Medication = (props) => {
  const medicine = getResourceData(
    props.value,
    props.data?.medicationReference?.reference
  )?.code;

  const doctorInfo = getResourceData(
    props.value,
    props.data?.requester?.reference
  );
  return (
    <div
      className="padding-left-larger padding-right-larger"
      data-cy={`Medication-card-${
        medicine?.coding ? medicine?.coding[0]?.display : medicine?.text
      }`}
    >
      <div className="font-size-medium text-uppercase font-family-gilroy-medium">
        Medication
      </div>
      <div className="padding-medium">
        <div className="flex-center-children-vertically flex-justify-content-space-between flex-direction-row">
          {props.data?.text?.div && (
            <div className="padding-top-small">{props.data?.text.div}</div>
          )}
          <div className="padding-top-small">
            <div className="font-size-large text-capitalize">
              {medicine?.coding ? medicine?.coding[0]?.display : medicine?.text}
            </div>
            <div className="font-size-large text-capitalize">
              {props.data?.medicationCodeableConcept?.text ?? ""}
            </div>
          </div>
        </div>

        <div className="font-size-large padding-top-large font-family-gilroy-medium text-capitalize">
          Dosage Instruction
        </div>
        {props.data?.text?.div && (
          <div className="padding-top-small">{props.data?.text.div}</div>
        )}
        {props.data?.dosageInstruction.map((data, index) => (
          <div
            className="font-size-large text-capitalize padding-top-default"
            key={`medicationRequest-${index}`}
          >
            {data.text}
          </div>
        ))}
        <div className="font-size-large padding-top-large font-family-gilroy-medium text-capitalize">
          Prescribed by
        </div>
        <div className="font-size-large padding-top-default">
          {doctorInfo?.name[0]?.prefix[0] ?? ""}.
          {doctorInfo?.name[0]?.text ?? ""}
        </div>
        {props.data?.authoredOn && (
          <>
            <div className="font-size-large padding-top-large font-family-gilroy-medium text-capitalize">
              Authored On
            </div>
            <div className="font-size-large padding-top-default">
              {dateAndTimeConverter(
                +new Date(props.data.authoredOn),
                "secondDate"
              )}
              {" | "}
              {dateAndTimeConverter(+new Date(props.data.authoredOn), "Time")}
            </div>
          </>
        )}
        {props.data?.reasonReference?.reason && (
          <>
            <div className="font-size-large padding-top-large font-family-gilroy-medium text-capitalize">
              Reason
            </div>
            <div className="font-size-large padding-top-default">
              {
                getResourceData(props.value, props.data.reasonReference.reason)
                  ?.code?.text
              }
            </div>
          </>
        )}
      </div>
    </div>
  );
};

const DocumentReference = (props) => {
  const resource = props.data?.content[0];
  const extension = resource?.attachment?.contentType?.split("/")[1];

  const documentBuffer = base64ToArrayBuffer(resource?.attachment?.data);

  const allowSupportedFileFormat = (data) => {
    switch (data) {
      case "application/pdf":
        return true;

      default:
        return false;
    }
  };

  function base64ToArrayBuffer(base64) {
    let binary_string = window.atob(base64);
    let len = binary_string.length;
    let bytes = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
      bytes[i] = binary_string.charCodeAt(i);
    }
    return bytes.buffer;
  }

  return (
    <div
      className="padding-left-larger padding-right-larger"
      data-cy="DocumentReference-card"
    >
      <div className="font-size-medium text-uppercase font-family-gilroy-medium">
        Document Reference
      </div>
      <div className="padding-medium">
        {props.data?.text?.div && (
          <div className="padding-top-small">{props.data?.text.div}</div>
        )}
        {allowSupportedFileFormat(resource?.attachment?.contentType) ? (
          <div
            className=" flex-justify-content-space-between flex-direction-column"
            onClick={() => {
              props.documentOnClick(documentBuffer);
            }}
          >
            <div className="padding-top-small">
              <div className="font-size-large">
                {resource?.attachment?.title ?? ""}.{extension ?? ""}
              </div>
            </div>
            <div
              data-cy="pdfRenderer"
              className="padding-top-large padding-bottom-default width-40-percentage"
            >
              <div className="inherit-parent-width display-flex flex-direction-column border-radius-default flex-center-children-horizontally flex-center-children-vertically height-120px background-color-grey">
                <img
                  data-cy="PDF-icon"
                  draggable="false"
                  className="height-width-48px"
                  src={pdfIcon}
                  alt="pdf icon"
                />
                <div className="adaptive-padding-top">View file</div>
              </div>
            </div>
          </div>
        ) : (
          <div className="padding-top-large padding-bottom-default width-40-percentage">
            <div className="inherit-parent-width display-flex flex-direction-column border-radius-default flex-center-children-horizontally flex-center-children-vertically height-120px background-color-grey">
              <img
                data-cy="unSupported-icon"
                draggable="false"
                className="height-width-48px"
                src={unsupportedIcon}
                alt="unsupported icon"
              />
              <div className="adaptive-padding-top">UnSupported</div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

const Organization = (props) => {
  const name = props.data?.name?.split(",")[0];
  const area = props.data?.name?.split(",")[1];
  const address = props.data?.address[0];
  const phoneNumber = props.data?.telecom;
  return (
    <div className="padding-larger" data-cy="Organization-card">
      <div className="padding-left-larger padding-right-larger">
        Organization
      </div>
      <div className="padding-medium">
        {props.data?.text?.div && (
          <div className="padding-top-small">{props.data?.text.div}</div>
        )}
        <div className="flex-center-children-vertically flex-justify-content-space-between flex-direction-row">
          <div className="padding-top-small">
            <div className="font-size-large">{name ?? ""}</div>
            <div className="font-size-small padding-top-small">
              {area ?? ""}
            </div>
          </div>
          <div className=" flex-center-children-horizontally flex-center-children-vertically">
            <RoundedProfilePicture
              data-cy="Organization-profile-picture"
              className="margin-top-default"
              size={"medium"}
              // src={googleFit}
            />
          </div>
        </div>
        <div className="font-size-small text-uppercase">Contact</div>
        {phoneNumber.map((data, index) => (
          <div className="font-size-large" key={`organization-${index}`}>
            {data.system} : {data.value}
          </div>
        ))}
        <div className="padding-top-large">
          {address?.line ?? ""} - {address.postalCode ?? ""}
        </div>
      </div>
    </div>
  );
};

const Immunization = (props) => {
  const vaccine = props.data?.vaccineCode;
  const reasonReference = props.data?.reasonReference;
  const reaction = props.data?.reaction;
  return (
    <div
      className="padding-left-larger padding-right-larger"
      data-cy={`Immunization-card-${
        vaccine?.coding ? vaccine?.coding[0]?.display : vaccine?.text
      }`}
    >
      <div className="font-size-medium text-uppercase font-family-gilroy-medium">
        Immunization
      </div>

      <div className="padding-medium">
        {props.data?.text?.div && (
          <div className="padding-top-small">{props.data?.text.div}</div>
        )}
        <div className=" flex-center-children-vertically flex-justify-content-space-between flex-direction-row">
          <div className="font-size-large">
            {vaccine?.coding ? vaccine?.coding[0]?.display : vaccine?.text}
          </div>
          <div className="font-size-small padding-top-small">
            {/* {Dose} {" : "} {vaccine.protocolApplied[0].doseNumberString} */}
          </div>
        </div>
        <div className="font-size-large padding-top-large font-family-gilroy-medium text-capitalize">
          Manufacturer
        </div>
        <div className="font-size-large">
          {props.data?.manufacturer?.reference ?? ""}
        </div>
      </div>
      {reasonReference?.map((data) => {
        return (
          data?.reference?.split("/")[0] === "DiagnosticReport" && (
            <div className="padding-bottom-default">
              <DiagnosticReport
                data={getResourceData(props.value, data?.reference)}
              />
            </div>
          )
        );
      })}
      {reaction?.map((data) => {
        return (
          data?.detail?.reference?.split("/")[0] === "Observation" && (
            <div className="padding-bottom-default">
              <Observation
                data={getResourceData(props.value, data?.detail?.reference)}
              />
            </div>
          )
        );
      })}
    </div>
  );
};

const Observation = (props) => {
  return (
    <div
      className="padding-left-larger padding-right-larger"
      data-cy={`Observation-card-${props.data?.code?.text}`}
    >
      <div className="font-size-medium text-uppercase font-family-gilroy-medium">
        Observation
      </div>
      <div className="padding-medium">
        {props.data?.text?.div && (
          <div className="padding-top-small">{props.data?.text.div}</div>
        )}
        <div className=" flex-center-children-vertically flex-justify-content-space-between flex-direction-row">
          <div className="padding-top-small">
            {props.data?.code?.text && !props.data?.valueQuantity && (
              <div className="font-size-large padding-top-small text-capitalize">
                {props.data?.code?.text ?? ""}
                {!props.data?.component ? " : " : ""}
                {props.data?.valueString ?? ""}
              </div>
            )}

            {props.data?.code?.coding && props.data?.valueQuantity && (
              <>
                <div className="font-size-large font-family-gilroy-medium text-capitalize">
                  {props.data?.code?.coding[0]?.display ?? ""}
                  {" : "}
                </div>
                <div className="font-size-large padding-top-default">
                  {props.data?.valueQuantity?.value ?? ""}{" "}
                  {props.data?.valueQuantity?.unit ?? ""}
                </div>
              </>
            )}

            {props.data?.code?.coding && props.data?.valueCodeableConcept && (
              <div className="font-size-large padding-top-small text-capitalize">
                {props.data?.code?.coding[0]?.display ?? ""}
                {" : "}
                {props.data?.valueCodeableConcept?.text ?? ""}
              </div>
            )}

            {props.data?.code?.text && props.data?.valueQuantity && (
              <div className="font-size-large padding-top-small text-capitalize">
                {props.data?.code?.text ?? ""}
                {" : "}
                {props.data?.valueQuantity.value ?? ""}{" "}
                {props.data?.valueQuantity.unit ?? ""}
              </div>
            )}
          </div>
        </div>
        {props.data?.component?.map((data, index) => (
          <div
            key={`Observation-${index}`}
            className={`font-size-small ${
              index === 0 ? "" : "padding-top-large"
            } text-uppercase`}
          >
            {data.code?.coding[0]?.display ?? ""} {" : "}{" "}
            {data.valueQuantity?.value ?? ""} {data.valueQuantity.unit ?? ""}
          </div>
        ))}
        {props.data?.effectiveDateTime && (
          <>
            <div className="font-size-large padding-top-large font-family-gilroy-medium text-capitalize">
              Effective date and time
            </div>
            <div className="font-size-large padding-top-default">
              {dateAndTimeConverter(
                +new Date(props.data?.effectiveDateTime),
                "secondDate"
              )}
              {" | "}
              {dateAndTimeConverter(
                +new Date(props.data?.effectiveDateTime),
                "Time"
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

const Condition = (props) => {
  const dataAndTime = props.data?.recordedDate
    ? +new Date(props.data?.recordedDate)
    : "";

  return (
    <div
      className="padding-left-larger padding-right-larger"
      data-cy="Condition-card"
    >
      <div className="font-size-medium text-uppercase font-family-gilroy-medium">
        Condition
      </div>
      <div className="padding-medium">
        {props.data?.text?.div && (
          <div className="padding-top-small">{props.data?.text.div}</div>
        )}
        <div className="font-size-large font-family-gilroy-medium text-capitalize">
          Description
        </div>
        <div className="font-size-large padding-top-default">
          {props.data?.code.text}
        </div>

        {props.data?.severity && (
          <>
            <div className="font-size-large padding-top-large font-family-gilroy-medium text-capitalize">
              Severity
            </div>
            <div className="font-size-large padding-top-default">
              {props.data?.severity?.text}
            </div>
          </>
        )}

        {props.data?.recordedDate && (
          <>
            <div className="font-size-large padding-top-large font-family-gilroy-medium text-capitalize">
              Recorded Date
            </div>
            <div className="font-size-large padding-top-default">
              {dateAndTimeConverter(dataAndTime, "secondDate")} {" | "}
              {dateAndTimeConverter(dataAndTime, "Time")}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

const Appointment = (props) => {
  const endDataAndTime = +new Date(props.data?.end);
  const StartDataAndTime = +new Date(props.data?.start);

  return (
    <div
      className="padding-left-larger padding-right-larger"
      data-cy="Appointment-card"
    >
      <div className="font-size-medium text-uppercase font-family-gilroy-medium">
        Appointment
      </div>
      <div className="padding-medium">
        {props.data?.text?.div && (
          <div className="padding-top-small">{props.data?.text.div}</div>
        )}
        <div className="flex-center-children-vertically flex-justify-content-space-between flex-direction-row">
          <div className="padding-top-small">
            <div className="font-size-large">
              {props.data?.description ?? ""}
            </div>
          </div>
        </div>
        <div className="font-size-large padding-top-large font-family-gilroy-medium text-capitalize">
          Timing
        </div>
        <div className="font-size-large padding-top-default">
          Start : {dateAndTimeConverter(StartDataAndTime, "secondDate")} {" | "}
          {dateAndTimeConverter(StartDataAndTime, "Time")}
        </div>
        <div className="font-size-large padding-top-default">
          End : {dateAndTimeConverter(endDataAndTime, "secondDate")} {" | "}
          {dateAndTimeConverter(endDataAndTime, "Time")}
        </div>
      </div>
    </div>
  );
};

const CarePlan = (props) => {
  const endDataAndTime = +new Date(props.data?.period?.end);
  const StartDataAndTime = +new Date(props.data?.period?.start);
  return (
    <div
      className="padding-left-larger padding-right-larger"
      data-cy="CarePlan-card"
    >
      <div className="font-size-medium text-uppercase font-family-gilroy-medium">
        CarePlan
      </div>
      <div className="padding-medium">
        {props.data?.text?.div && (
          <div className="padding-top-small">{props.data?.text.div}</div>
        )}
        {props.data?.text?.div && (
          <div className="padding-top-small">{props.data?.text.div}</div>
        )}
        <div className="font-size-large font-family-gilroy-medium text-capitalize">
          Description
        </div>
        <div className="font-size-large padding-top-default">
          {props.data?.description ?? ""}
        </div>
        <div className="font-size-large padding-top-large font-family-gilroy-medium text-capitalize">
          Timing
        </div>
        <div className="font-size-large padding-top-default">
          Start : {dateAndTimeConverter(StartDataAndTime, "secondDate")}
          {" | "}
          {dateAndTimeConverter(StartDataAndTime, "Time")}
        </div>
        <div className="font-size-large padding-top-default">
          End : {dateAndTimeConverter(endDataAndTime, "secondDate")} {" | "}
          {dateAndTimeConverter(endDataAndTime, "Time")}
        </div>
      </div>
    </div>
  );
};

const AllergyIntolerance = (props) => {
  return (
    <div
      className="padding-left-larger padding-right-larger"
      data-cy={`AllergyIntolerance-card-${props.data?.category ?? ""}`}
    >
      <div className="font-size-medium text-uppercase font-family-gilroy-medium">
        Allergy
      </div>
      <div className="padding-medium">
        {props.data?.text?.div && (
          <div className="padding-top-small">{props.data?.text.div}</div>
        )}
        <div className=" flex-center-children-vertically flex-justify-content-space-between flex-direction-row">
          <div className="padding-top-small">
            <div className="font-size-large text-capitalize">
              {props.data?.category ?? ""}
              {" : "}
              {props.data?.code?.coding[0]?.display ?? ""}
            </div>
          </div>
        </div>
        <div className="font-size-large padding-top-large font-family-gilroy-medium text-capitalize">
          Severity
        </div>
        <div className="font-size-large padding-top-default text-capitalize">
          {props.data?.criticality ?? ""}
        </div>
        <div className="font-size-large padding-top-large font-family-gilroy-medium text-capitalize">
          Duration
        </div>
        <div className="font-size-large padding-top-default text-capitalize">
          {props.data?.onsetString ?? ""}
        </div>
        {props.data?.note && (
          <div className="font-size-large padding-top-large font-family-gilroy-medium text-capitalize">
            Notes
            <div className="padding-top-default font-family-gilroy-regular">
              {props.data?.note.map((data, index) => (
                <div
                  key={`allergyIntolerance${index}`}
                  className="font-size-large text-capitalize"
                >
                  {data.text}
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

const Procedure = (props) => {
  const dataAndTime = +new Date(props.data?.performedDateTime);
  return (
    <div
      className="padding-left-larger padding-right-larger"
      data-cy="Procedure-card"
    >
      <div className="font-size-medium text-uppercase font-family-gilroy-medium">
        Procedure
      </div>
      <div className="padding-medium">
        {props.data?.text?.div && (
          <div className="padding-top-small">{props.data?.text.div}</div>
        )}
        <div className="flex-center-children-vertically flex-justify-content-space-between flex-direction-row">
          <div className="padding-top-small">
            <div className="font-size-large">
              {props.data?.code?.coding[0]?.display ?? ""}
            </div>
          </div>
        </div>
        {props.data?.performer && (
          <>
            <div className="font-size-large padding-top-large font-family-gilroy-medium text-capitalize">
              Performer
            </div>
            <div className="font-size-large">
              {props.data?.performer?.map((data, index) => {
                const doctor = getResourceData(
                  props.value,
                  data.actor?.reference
                );
                return (
                  <div key={index}>
                    <div className="font-size-large">
                      {doctor?.name[0]?.prefix[0] ?? ""}.
                      {doctor?.name[0]?.text ?? ""}
                    </div>
                    <div className="font-size-small padding-top-small">
                      {doctor?.name[0]?.suffix[0] ?? ""}
                    </div>
                  </div>
                );
              })}
            </div>
          </>
        )}
        <div className="font-size-large padding-top-large font-family-gilroy-medium text-capitalize">
          Performed Date
        </div>
        <div className="font-size-large padding-top-default">
          {dateAndTimeConverter(dataAndTime, "secondDate")} {" | "}
          {dateAndTimeConverter(dataAndTime, "Time")}
        </div>
        <div className="font-size-large padding-top-large font-family-gilroy-medium text-capitalize">
          Complications
        </div>
        {props.data?.complication.map((data, index) => (
          <div
            key={`Procedure-${index}`}
            className="font-size-large padding-top-default"
          >
            {data.coding[0]?.display ?? ""}
          </div>
        ))}
      </div>
    </div>
  );
};

const DiagnosticReport = (props) => {
  return (
    <div
      className="padding-left-larger padding-right-larger"
      data-cy={`DiagnosticReport-card-${
        props.data?.code?.text
          ? props.data?.code?.text
          : props.data?.code?.coding[0]?.display
      }`}
    >
      <div className="font-size-medium text-uppercase font-family-gilroy-medium">
        DiagnosticReport
      </div>
      {props.data?.text?.div && (
        <div className="padding-top-small">{props.data?.text.div}</div>
      )}
      <div className=" flex-center-children-vertically flex-justify-content-space-between flex-direction-row">
        <div className="padding-top-small">
          <div className="font-size-large">
            {props.data?.code?.text
              ? props.data?.code?.text
              : props.data?.code?.coding[0]?.display}
          </div>
        </div>
      </div>
      <div className=" padding-top-larger">
        <div className="font-size-large padding-top-large font-family-gilroy-medium text-capitalize">
          Conclusion
          <div className="font-size-large text-lowercase">
            {props.data?.conclusion ?? ""}
          </div>
        </div>
      </div>
    </div>
  );
};

const DropDownButton = (props) => {
  return (
    <div
      className={`${props.className} cursor-pointer`}
      onClick={props.dropDownClick}
      data-cy={props["dropDownButton-data-cy"]}
    >
      <img
        className={`${
          props.showData === true ? "rotate-180" : ""
        } padding-small`}
        src={dropDownArrow}
        alt="dropdown"
      />
    </div>
  );
};

const getResourceData = (value, fullUrl) => {
  const data = value?.entry?.find((data) => data?.fullUrl === fullUrl);
  return data?.resource;
};

FHIRRenderer.propTypes = {
  /**
   * JSON FHIR data
   */
  data: PropTypes.object,
  /**
   * view document on click
   */
  documentOnClick: PropTypes.func,
  /**
   * type of user
   */
  userType: PropTypes.oneOf(["clinic", "patient", "doctor"]),
  /**
   * to get profile picture
   */
  document: PropTypes.object
};

FHIRRenderer.defaultProps = {
  data: null,
  documentOnClick: undefined,
  userType: "",
  document: undefined
};
