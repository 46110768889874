import store from "../store/store";
import { abdmActionTypes } from "./saga";

/**
 * @module AbdmSaga
 */

/**
 * Set ABDM HIU ID
 * @param {string} hiuId
 */
export function putAbdmHiuId(hiuId) {
  store.dispatch({
    type: abdmActionTypes.PUT_ABDM_HIU_ID,
    payload: {
      hiuId: hiuId
    }
  });
}
/**
 * get ConsumerConsent of patients
 * @param {string} hiuId
 */
export function getConsumerConsent() {
  store.dispatch({
    type: abdmActionTypes.GET_CONSUMER_CONSENT
  });
}

/**
 * Set subscription data
 * @param {object} data
 */
export function setSubscriptions(data) {
  store.dispatch({
    type: abdmActionTypes.SET_SUBSCRIPTIONS,
    payload: {
      data: data
    }
  });
}
