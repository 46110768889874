import React from "react";
import "./CheckBox.css";
import PropTypes from "prop-types";

function CheckBox(props) {
  return (
    <div
      className="user-select-none font-size-small flex-center-children-vertically cursor-pointer"
      data-cy={props["data-cy"] ? props["data-cy"] : "checkbox"}
    >
      <input
        id={props.id ? props.id : "checkbox-input"}
        type="checkbox"
        name={props.name ? props.name : "checkBox"}
        onChange={(event) => {
          if (typeof props.onChange === "function") {
            props.onChange(event);
          }
        }}
        checked={props.checked ? props.checked : null}
        data-cy={props.id ? props.id : "checkbox-input"}
        className="display-none cursor-pointer"
        disabled={props.disabled}
      />
      <label
        data-cy="custom-checkbox"
        htmlFor={props.id ? props.id : "checkbox-input"}
        className="position-relative background-white check-box-size cursor-pointer checkmark"
      ></label>
      {props.label && (
        <label
          htmlFor={props.id ? props.id : "checkbox-input"}
          className={` ${
            props.labelClassName ? props.labelClassName : ""
          } display-block position-relative cursor-pointer font-size-small user-select-none padding-left-default`}
        >
          {props.label}
        </label>
      )}
    </div>
  );
}
CheckBox.propTypes = {
  /**
   * label which you want to give
   */
  label: PropTypes.string.isRequired,
  /**
   * cypress selector attribute for testing
   */
  "data-cy": PropTypes.string,
  /**
   * Checkbox input name attribute, default name is `name='checkbox'`
   */
  name: PropTypes.string,
  /**
   * onChange function prop `onChange={(event)=>{}}`
   */
  onChange: PropTypes.func
};

CheckBox.defaultProps = {
  label: "",
  "data-cy": "checkbox",
  name: "checkbox",
  onChange: undefined
};

export default CheckBox;
