import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import EditAccountInformation from "../../components/EditAccountInformation/EditAccountInformation";
import CreateEditPreviewForm from "../../components/CreateEditPreviewForm/CreateEditPreviewForm";
import SelectedDocument from "../../components/SelectedDocument/SelectedDocument";
import SelectedPatient from "../../components/SelectedPatient/SelectedPatient";
import AbhaAuthentication from "../../components/Abha/AbhaAuthentication";
import AbhaLogin from "../../components/ManageABHA/AbhaLogin";
import ManageClinic from "../../components/ManageClinic/ManageClinic";
import { setErrorStatus } from "../../redux/status/actions";
import { showSecondary, showTertiary } from "../../redux/navigation/actions";
import { setSelectedClinic } from "../../redux/connection/actions";
import {
  createAbhaNumber,
  resetStatus,
  sendAadhaarOtp,
  sendAbhaOtp,
  sendMobileOtp,
  verifyAadhaarOtp,
  verifyAbhaOtpAndLogin
} from "../../redux/abha/actions";
import { createForm, updateForm } from "../../redux/form/actions";
import { checkIfPatientConnectedToAbdm } from "../../redux/abdmPatients/actions";
import { removeDocumentSearchData } from "../../redux/documentSearch/actions";
import { setDownloadUrl } from "../../redux/history/actions";
import Query from "../../components/Query/Query";
import QuerySuccess from "../../components/Query/QuerySuccess";
import { createQuery } from "../../redux/queries/actions";
import ManageABHA from "../../components/ManageABHA/ManageABHA";

function Secondary(props) {
  const [isRequiredDataPresent, setIsRequiredDataPresent] = useState(false);

  // For checking secondary and tertiary component required data are present
  useEffect(() => {
    const selectedPatientData = () => {
      if (props.abdmPatients.selectedPatient) {
        return true;
      } else {
        return false;
      }
    };

    const selectedDoctorData = () => {
      if (props.doctorSearch.selectedDoctor) {
        return true;
      } else {
        return false;
      }
    };
    const selectedDocumentData = () => {
      if (props.documentSearch.selectedDocument) {
        return true;
      } else {
        return false;
      }
    };

    const accountInformationData = () => {
      if (props.navigation.primary === "accountInformation") {
        return true;
      } else {
        return false;
      }
    };

    const editPreviewFormData = () => {
      if (
        props.navigation.primary === "form" &&
        props.navigation.secondary === "createEditPreviewForm"
      ) {
        return true;
      } else {
        return false;
      }
    };

    const createAbhaNumber = () => {
      if (props.navigation.primary === "abha") {
        return true;
      } else {
        return false;
      }
    };

    const abhaLogin = () => {
      if (
        props.navigation.primary === "abha" &&
        props.navigation.secondary === "abhaLogin"
      ) {
        return true;
      } else {
        return false;
      }
    };

    if (
      selectedPatientData() ||
      selectedDoctorData() ||
      selectedDocumentData() ||
      accountInformationData() ||
      editPreviewFormData() ||
      createAbhaNumber() ||
      abhaLogin()
    ) {
      return setIsRequiredDataPresent(true);
    } else {
      return setIsRequiredDataPresent(false);
    }
  }, [
    props.doctorSearch.selectedDoctor,
    props.documentSearch.selectedDocument,
    props.navigation.primary,
    props.navigation.secondary,
    props.abdmPatients.selectedPatient
  ]);
  return (
    <>
      {props.navigation.primary &&
        props.navigation.secondary &&
        isRequiredDataPresent && (
          <div className="width-300px min-width-270px background-white border-radius-right box-shadow-left-none card-box-border font-size-medium padding-larger height-95-percent hide-scroll-bar">
            {props.navigation.secondary === "selectedPatient" && (
              <SelectedPatient
                navigation={props.navigation}
                abdmPatients={props.abdmPatients}
                handleProfileClose={() => {
                  props.showSecondary(null);
                  props.showTertiary(null);
                }}
                showTertiary={(data) => {
                  props.showTertiary(data);
                }}
              />
            )}
            {props.navigation.secondary === "selectedDocument" && (
              <SelectedDocument
                history={props.history}
                remoteConfig={props.remoteConfig}
                connection={props.connection}
                documentSearch={props.documentSearch}
                notification={props.notification}
                setDownloadUrl={(url, documentId) => {
                  props.setDownloadUrl(url, documentId);
                }}
                removeDocumentSearchData={(data) => {
                  props.removeDocumentSearchData(data);
                }}
                documentDeleteOnClick={props.documentDeleteOnClick}
              />
            )}
            {props.navigation.secondary === "editAccountInformation" && (
              <EditAccountInformation
                navigation={props.navigation}
                verificationOnClick={() => {
                  props.showTertiary("verification");
                }}
                escalationContactOnClick={() => {
                  props.showTertiary("escalationContact");
                }}
                editProfileOnClick={() => {
                  props.showTertiary("editProfile");
                }}
              />
            )}
            {props.navigation.secondary === "createEditPreviewForm" && (
              <CreateEditPreviewForm
                forms={props.forms}
                createForm={(data) => {
                  props.createForm(data);
                }}
                updateForm={(data) => {
                  props.updateForm(data);
                }}
              />
            )}
            {props.navigation.secondary === "manageClinic" && (
              <ManageClinic
                title="MANAGE CLINIC STAFFS"
                onProfileCardClick={(data) => {
                  if (
                    data.userType === "owner" ||
                    data.userType === "manager"
                  ) {
                    if (data.verified === true) {
                      props.setSelectedClinic(data.connectionId);
                      props.showTertiary("personnel");
                    } else {
                      setErrorStatus({
                        code: "custom",
                        message: "Selected clinic has`t been verified"
                      });
                    }
                  }
                }}
                closeTertiary={() => {
                  props.showTertiary(null);
                }}
                connection={props.connection}
                navigate={props.navigate}
              />
            )}

            {props.navigation.secondary === "clinicsInformation" && (
              <ManageClinic
                title="CLINIC"
                onProfileCardClick={(data) => {
                  if (
                    data.userType === "owner" ||
                    data.userType === "manager"
                  ) {
                    if (data.verified === true) {
                      props.setSelectedClinic(data.connectionId);
                      props.showTertiary("editClinic");
                    } else {
                      setErrorStatus({
                        code: "custom",
                        message: "Selected clinic has`t been verified"
                      });
                    }
                  }
                }}
                closeTertiary={() => {
                  props.showTertiary(null);
                }}
                connection={props.connection}
                navigate={props.navigate}
              />
            )}

            {props.navigation.secondary === "createAbhaNumber" && (
              <AbhaAuthentication
                auth={props.auth}
                abha={props.abha}
                resetStatus={() => {
                  props.resetStatus();
                }}
                manageABHANumberOnClick={() => {
                  props.showSecondary("abhaLogin");
                }}
                sendAadhaarOtp={(aadhaarNumber) => {
                  props.sendAadhaarOtp(aadhaarNumber);
                }}
                verifyAadhaarOtp={(aadhaarOtp, transactionId) => {
                  props.verifyAadhaarOtp(aadhaarOtp, transactionId);
                }}
                sendMobileOtp={(phoneNumber, transactionId) => {
                  props.sendMobileOtp(phoneNumber, transactionId);
                }}
                createAbhaNumber={(otp, transactionId, healthId) => {
                  props.createAbhaNumber(otp, transactionId, healthId);
                }}
              />
            )}
            {props.navigation.secondary === "query" && (
              <Query
                queries={props.queries}
                createQuery={(data) => {
                  props.createQuery(data);
                }}
              />
            )}
            {props.navigation.secondary === "querySuccess" && (
              <QuerySuccess
                okayOnClick={() => {
                  props.showSecondary(null);
                }}
              />
            )}

            {props.navigation.secondary === "abhaLogin" &&
              (!(
                props.abha.accessToken &&
                typeof props.abha.accessToken === "string"
              ) ? (
                <AbhaLogin
                  abha={props.abha}
                  sendAbhaOtp={(loginData) => {
                    props.sendAbhaOtp(loginData);
                  }}
                  resetStatus={() => {
                    props.resetStatus();
                  }}
                  verifyAbhaOtpAndLogin={(abhaLoginOtp, loginData) => {
                    props.verifyAbhaOtpAndLogin(abhaLoginOtp, loginData);
                  }}
                />
              ) : (
                <ManageABHA
                  abha={props.abha}
                  navigation={props.navigation}
                  showTertiary={(data) => {
                    props.showTertiary(data);
                  }}
                  logout={() => {
                    props.resetStatus();
                    props.showSecondary(null);
                  }}
                />
              ))}
          </div>
        )}
    </>
  );
}

const mapStateToProps = function (state) {
  return {
    auth: state.auth,
    navigation: state.navigation,
    connection: state.connection,
    doctorSearch: state.doctorSearch,
    documentSearch: state.documentSearch,
    abdmPatients: state.abdmPatients,
    abha: state.abha,
    history: state.history,
    notification: state.notification,
    forms: state.forms,
    queries: state.queries
  };
};

const mapDispatchToProps = function () {
  return {
    showTertiary: (data) => showTertiary(data),
    showSecondary: (data) => showSecondary(data),
    createQuery: (data) => createQuery(data),
    checkIfPatientConnectedToAbdm: () => checkIfPatientConnectedToAbdm(),
    createForm: (formData) => createForm(formData),
    updateForm: (formData) => updateForm(formData),
    setSelectedClinic: (data) => setSelectedClinic(data),
    sendAbhaOtp: (loginData) => sendAbhaOtp(loginData),
    resetStatus: () => resetStatus(),
    verifyAbhaOtpAndLogin: (otp, loginData) =>
      verifyAbhaOtpAndLogin(otp, loginData),
    sendAadhaarOtp: (aadhaarNumber) => sendAadhaarOtp(aadhaarNumber),
    verifyAadhaarOtp: (otp, transactionId) =>
      verifyAadhaarOtp(otp, transactionId),
    sendMobileOtp: (phoneNumber, transactionId) =>
      sendMobileOtp(phoneNumber, transactionId),
    createAbhaNumber: (otp, transactionId, healthId) =>
      createAbhaNumber(otp, transactionId, healthId),
    removeDocumentSearchData: (deepClear) =>
      removeDocumentSearchData(deepClear),
    setDownloadUrl: (url, documentId) => setDownloadUrl(url, documentId)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Secondary);
