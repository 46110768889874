import React, { useState, useRef, useEffect } from "react";
import { validValue } from "../../front-end-global-components/utils/Functions";
import { isValidObject, isValidString } from "../../utils/validators";
import addGreyIcon from "../../assets/icons/add_grey.svg";
import check from "../../assets/icons/success.svg";
import editIcon from "../../assets/icons/Edit_v2.svg";
import "./CreateEditPreviewForm.css";
import { isValidArray } from "../../front-end-global-components/services/validators";
import {
  defaultFields,
  dataTypeConversion
} from "../../front-end-global-components/utils/constants";
import Button from "../../front-end-global-components/components/Button/Button";
import ChipInputBox from "../../front-end-global-components/components/ChipInputBox/ChipInputBox";

export default function CreateEditPreviewForm(props) {
  const currentFormTrueData = useRef(null);
  const [name, setName] = useState("");
  const [fields, setFields] = useState([]);
  const isUpdateRef = useRef(false);
  const [isTemplateModified, setIsTemplateModified] = useState(false);

  const addField = (fieldData) => {
    if (isUpdateRef.current === true && isTemplateModified === false) {
      setIsTemplateModified(true);
    }
    setFields((prevState) => {
      return [...prevState, fieldData];
    });
  };

  const updateField = (fieldData, index) => {
    if (isUpdateRef.current === true && isTemplateModified === false) {
      setIsTemplateModified(true);
    }
    setFields((prevState) => {
      const tempArray = [...prevState];
      tempArray.splice(index, 1, fieldData);
      return [...tempArray];
    });
  };

  const removeField = (index) => {
    if (isUpdateRef.current === true && isTemplateModified === false) {
      setIsTemplateModified(true);
    }
    setFields((prevState) => {
      const tempArray = [...prevState];
      tempArray.splice(index, 1);
      return [...tempArray];
    });
  };

  useEffect(() => {
    currentFormTrueData.current = validValue(
      () => props.forms.data[props.forms.currentForm]
    );
    if (isTemplateModified === true) {
      setIsTemplateModified(false);
    }
    if (
      isValidObject(currentFormTrueData.current) &&
      isValidArray(currentFormTrueData.current.fields) &&
      isValidString(currentFormTrueData.current.name)
    ) {
      isUpdateRef.current = true;
      setFields([...currentFormTrueData.current.fields]);
      setName(currentFormTrueData.current.name);
    } else {
      isUpdateRef.current = false;
      setFields([]);
      setName("");
    }
    // eslint-disable-next-line
  }, [props.forms.data, props.forms.currentForm]);

  const isSaveButtonDisabled = () => {
    if (isUpdateRef.current === true) {
      if (
        isValidString(name) &&
        isValidArray(fields) &&
        isTemplateModified === true
      ) {
        return false;
      } else {
        return true;
      }
    } else {
      if (isValidString(name) && isValidArray(fields)) {
        return false;
      } else {
        return true;
      }
    }
  };

  return (
    <div
      className="inherit-parent-height"
      data-cy="create-preview-edit-template"
    >
      <div className="inherit-parent-height display-block flex-direction-column flex-justify-content-space-between ">
        <section>
          <div className=" font-color-secondary text-align-center font-family-gilroy-regular padding-top-default font-size-small padding-bottom-default text-transform-uppercase letter-spacing-4-percentage ">
            Create new template
          </div>
          <ChipInputBox
            size="small"
            label="Form Title"
            name="formTitle"
            type="text"
            removeResponsive={true}
            className="padding-right-medium"
            value={name}
            disabled={isUpdateRef.current}
            onChange={(event) => {
              setName(event.target.value);
            }}
          />
          <AddInputField
            options={defaultFields}
            {...props}
            fields={fields}
            addField={addField}
            updateField={updateField}
            removeField={removeField}
          />
        </section>

        <section className="flex-basis-100 ">
          {fields.length > 0 && (
            <div className=" font-color-secondary text-align-center font-family-gilroy-regular padding-top-large font-size-small text-transform-uppercase letter-spacing-4-percentage ">
              All fields
            </div>
          )}
          {fields.map((data, index) => {
            return (
              <EditInputField
                field={data}
                key={index}
                index={index}
                fields={fields}
                addField={addField}
                updateField={updateField}
                removeField={removeField}
                isTemplateModified={isTemplateModified}
                isUpdateRef={isUpdateRef.current}
                {...props}
              />
            );
          })}
        </section>

        <div className="display-flex flex-direction-row flex-justify-content-space-between margin-vertical-medium">
          <Button
            data-cy={
              isUpdateRef.current === true
                ? "update-template-btn"
                : "save-template-btn"
            }
            boxShadow={false}
            text={isUpdateRef.current === true ? "Update" : "Save"}
            type="submit"
            className="inherit-parent-width padding-medium border-radius-default margin-right-default"
            disabled={isSaveButtonDisabled()}
            loading={props.forms.loading}
            onClick={() => {
              if (isUpdateRef.current === true) {
                props.updateForm({
                  name: name,
                  fields: [...fields]
                });
              } else {
                props.createForm({
                  name: name,
                  fields: [...fields]
                });
              }
            }}
          />
          <Button
            data-cy="create-template-button"
            boxShadow={false}
            text="Cancel"
            type="submit"
            variant="secondary"
            className="inherit-parent-width padding-medium border-radius-default margin-left-default"
            disabled={false}
            onClick={() => {
              props.showSecondary(null);
            }}
          />
        </div>
      </div>
    </div>
  );
}

const AddInputField = (props) => {
  const parentElementRef = useRef(null);
  const addInputFieldRef = useRef(null);
  const [searchedOptions, setSearchedOptions] = useState([]);
  const [openOptions, setOpenOptions] = useState(false);
  const shouldShowAddOptionRef = useRef(null);

  useEffect(() => {
    setSearchedOptions([...props.options]);

    if (openOptions === false) {
      shouldShowAddOptionRef.current = null;
    }
    // eslint-disable-next-line
  }, [openOptions]);

  useEffect(() => {
    addInputFieldRef.current.value = "";
  }, [props.fields]);

  useEffect(() => {
    function handleClickOutside(event) {
      const parentChildren = validValue(
        () => parentElementRef.current.children
      );
      if (
        !(
          parentChildren !== null &&
          (parentChildren[0].contains(event.target) ||
            (parentChildren[1] && parentChildren[1].contains(event.target)))
        )
      ) {
        setOpenOptions(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
    // eslint-disable-next-line
  }, [parentElementRef]);

  const onSearch = (text) => {
    if (text.length === 0) {
      shouldShowAddOptionRef.current = null;
      setSearchedOptions([...props.options]);
      return;
    }
    const _text = text.toLowerCase().split(" ");
    shouldShowAddOptionRef.current = `${text}`;
    const searchedList = props.options.filter((item) => {
      const _item = item.name.toLowerCase();
      if (_item === text.toLowerCase()) {
        shouldShowAddOptionRef.current = null;
      }
      return _text.every((el) => {
        return _item.indexOf(el) > -1;
      });
    });
    setSearchedOptions([...searchedList]);
  };

  const isSelected = (name) => {
    const result = props.fields.some((item, _index) => {
      if (item.name === name) {
        return true;
      } else {
        return false;
      }
    });
    return result;
  };

  const onAddField = (index, name) => {
    if (isSelected(name) === false) {
      props.addField(searchedOptions[index]);
    }
    setOpenOptions(false);
  };

  return (
    <>
      <section
        className="inherit-parent-width margin-vertical-small "
        ref={parentElementRef}
      >
        <div className="padding-vertical-default border-none border-bottom-1px-solid-tertiaryColor">
          <label className="font-family-gilroy-regular font-color-tertiary font-size-small ">
            ADD INPUT FIELD
          </label>
          <input
            ref={addInputFieldRef}
            className=" border-none inherit-parent-width place-holder font-color-secondary font-size-medium font-family-gilroy-regular"
            onChange={(event) => {
              onSearch(event.target.value);
            }}
            onFocus={() => {
              setOpenOptions(true);
            }}
            placeholder="Select / add"
            data-cy="add-input-field"
          />
        </div>
        {openOptions === true && (
          <div className="inherit-parent-width position-relative">
            <div className="left-50-percentage top-0 z-index-1 background-color-white transform-translateX--50-percentage width-112-percentage inherit-parent-width position-absolute padding-default box-shadow-default border-radius-default max-height-300px overflow-y-auto margin-top-default ">
              {searchedOptions.map((data, index) => {
                return (
                  <div
                    className={`cursor-pointer padding-small  display-flex-direction-row flex-align-items-center flex-justify-content-space-between display-flex `}
                    key={`default-option-${index}`}
                    data-cy={`default-option-${data.name}`}
                    onClick={() => {
                      onAddField(index, data.name);
                      addInputFieldRef.current.value = "";
                    }}
                  >
                    <p className="font-family-gilroy-regular font-color-secondary font-size-default text-transform-capitalize">
                      {data.name}
                    </p>
                    {isSelected(data.name) === true && (
                      <img
                        className="display-inline-block margin-left-auto"
                        src={check}
                        alt="check"
                      />
                    )}
                  </div>
                );
              })}

              {isValidString(shouldShowAddOptionRef.current) === true && (
                <>
                  <button
                    className="inherit-parent-width padding-top-default padding-bottom-default background-color-white flex-direction-row flex-justify-content-space-between "
                    onClick={() => {
                      props.addField({
                        type: "string",
                        name: shouldShowAddOptionRef.current,
                        required: true
                      });
                      setOpenOptions(false);
                      addInputFieldRef.current.value = "";
                    }}
                    data-cy="add-custom-value-button"
                  >
                    <span className="font-family-gilroy-regular font-size-medium font-color-secondary ">
                      Add "{`${shouldShowAddOptionRef.current}`}"
                    </span>
                    <img src={addGreyIcon} alt="add" />
                  </button>
                </>
              )}
            </div>
          </div>
        )}
      </section>
    </>
  );
};

const EditInputField = (props) => {
  const parentElementRef = useRef(null);
  const [fieldData, setFieldData] = useState({});
  const [openEditCard, setOpenEditCard] = useState(false);

  useEffect(() => {
    const isDefaultValue = validValue(() =>
      defaultFields.some((_field) => _field.name === props.field.name)
    );

    setFieldData({ ...props.field, defaultValue: isDefaultValue });
  }, [props.field, openEditCard]);

  useEffect(() => {
    const isDefaultValue = validValue(() =>
      defaultFields.some((_field) => _field.name === props.field.name)
    );

    if (
      isDefaultValue === false &&
      !(props.isUpdateRef === true && props.isTemplateModified === false)
    ) {
      if (openEditCard === true) {
        setOpenEditCard(false);
      } else {
        setOpenEditCard(true);
      }
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    function handleClickOutside(event) {
      const parentChildren = validValue(
        () => parentElementRef.current.children
      );
      if (
        !(
          parentChildren !== null &&
          (parentChildren[0].contains(event.target) ||
            (parentChildren[1] && parentChildren[1].contains(event.target)))
        )
      ) {
        setOpenEditCard(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
    // eslint-disable-next-line
  }, [parentElementRef]);

  const dataTypeOptions = ["string", "float", "timestamp"];

  return (
    <>
      <section className="margin-vertical-small" ref={parentElementRef}>
        <div
          className=" cursor-pointer border-none border-bottom-1px-solid-tertiaryColor inherit-parent-width padding-vertical-default "
          data-cy={`edit-input-field-${fieldData.name}-${fieldData.type}-${
            fieldData.required ? "required" : "unRequired"
          }`}
          onClick={(event) => {
            event.stopPropagation();
            setOpenEditCard(true);
          }}
        >
          <div className="inherit-parent-width display-flex flex-direction-row flex-justify-content-space-between flex-align-items-center ">
            <div>
              <label className="font-family-gilroy-regular font-color-tertiary font-size-small text-transform-uppercase ">
                {fieldData.name} {fieldData.required && <>&#42;</>}
              </label>
              <p className="font-family-gilroy-regular font-color-secondary font-size-default ">
                {dataTypeConversion(fieldData.type)}
              </p>
            </div>
            <img className="height-width-16-px" src={editIcon} alt="edit" />
          </div>
        </div>
        {openEditCard === true && (
          <div
            className="inherit-parent-width position-relative "
            data-cy={`edit-input-field-${fieldData.name}-edit-card`}
          >
            <div className=" left-50-percentage transform-translateX--50-percentage width-112-percentage inherit-parent-width background-color-white position-absolute  box-shadow-default border-radius-default max-height-300px">
              {fieldData.defaultValue === false && (
                <>
                  <p className="font-family-gilroy-regular font-color-secondary font-size-small text-align-center margin-top-medium margin-bottom-small ">
                    Select Datatype
                  </p>

                  {dataTypeOptions.map((dataType, index) => {
                    return (
                      <div
                        key={`data-type-${index}`}
                        data-cy={`data-type-${dataType}-${
                          dataType === fieldData.type
                            ? "selected"
                            : "unselected"
                        }`}
                        className={` cursor-pointer display-flex-direction-row flex-align-items-center flex-justify-content-space-between padding-default display-flex  ${
                          dataType === fieldData.type
                            ? "background-color-grey"
                            : "background-color-white"
                        } `}
                        onClick={() => {
                          setFieldData((prevState) => {
                            return {
                              ...prevState,
                              type: dataType
                            };
                          });
                        }}
                      >
                        <p className="display-inline-block font-family-gilroy-regular font-color-secondary font-size-default ">
                          {dataTypeConversion(dataType)}
                        </p>
                        {dataType === fieldData.type && (
                          <img
                            className="display-inline-block margin-left-auto"
                            src={check}
                            alt="check"
                          />
                        )}
                      </div>
                    );
                  })}
                </>
              )}

              <div className="padding-horizontal-default inherit-parent-width display-flex padding-bottom-large text-align-left font-size-small font-family-gilroy-medium margin-top-small">
                <div className="user-select-none font-size-small flex-center-children-vertically">
                  <input
                    id={`${fieldData.name}-required-field-checkbox`}
                    data-cy={`${fieldData.name}-required-field-checkbox`}
                    type="checkbox"
                    value={fieldData.required}
                    defaultChecked={fieldData.required}
                    name={fieldData.name ? fieldData.name : "checkBox"}
                    onChange={(_) => {
                      setFieldData((prevState) => {
                        return {
                          ...prevState,
                          required: !prevState.required
                        };
                      });
                    }}
                  />
                  <span className="position-relative background-white check-box-size checkmark"></span>
                  <label
                    htmlFor={`${fieldData.name}-required-field-checkbox`}
                    className="display-block position-relative cursor-pointer font-size-small user-select-none padding-left-default font-color-secondary font-size-medium "
                  >
                    Required Field
                  </label>
                </div>
              </div>

              <div className="padding-default display-flex flex-direction-row flex-justify-content-space-between">
                <Button
                  data-cy="update-field-btn"
                  boxShadow={false}
                  text="Update"
                  type="button"
                  className="inherit-parent-width padding-medium border-radius-default margin-right-default "
                  disabled={false}
                  onClick={() => {
                    props.updateField(
                      {
                        type: fieldData.type,
                        name: fieldData.name,
                        required: fieldData.required
                      },
                      props.index
                    );
                    setOpenEditCard(false);
                  }}
                />
                <Button
                  data-cy="remove-field-btn"
                  boxShadow={false}
                  text="Remove"
                  type="button"
                  variant="secondary"
                  className="inherit-parent-width padding-medium border-radius-default margin-left-default "
                  disabled={false}
                  onClick={() => {
                    props.removeField(props.index);
                    setOpenEditCard(false);
                  }}
                />
              </div>
            </div>
          </div>
        )}
      </section>
    </>
  );
};
