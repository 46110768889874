//Ripple function
export function rippleEffect(event) {
  //parent element
  const parentElement = event.currentTarget;
  const containerElement = event.currentTarget.parentElement;
  const viewportOffset = parentElement.getBoundingClientRect();
  parentElement.classList.add("ripple-element");

  const circle = document.createElement("span");
  circle.classList.add("ripple-span");
  const diameter = Math.max(
    parentElement.clientWidth,
    parentElement.clientHeight
  );
  const radius = diameter / 2;

  circle.style.width = circle.style.height = `${diameter}px`;
  circle.style.left = `${
    event.clientX +
    containerElement.scrollLeft -
    parentElement.offsetLeft -
    radius
  }px`;
  circle.style.top = `${
    event.clientY + containerElement.scrollTop - viewportOffset.y - radius
  }px`;
  circle.classList.add("ripples");

  const ripple = parentElement.getElementsByClassName("ripples")[0];

  if (ripple) {
    ripple.remove();
  }
  //Add ripple element
  parentElement.appendChild(circle);
}
function HsvaToRgba(h, s, v, a) {
  let r, g, b, i, f, p, q, t;
  i = Math.floor(h * 6);
  f = h * 6 - i;
  p = v * (1 - s);
  q = v * (1 - f * s);
  t = v * (1 - (1 - f) * s);
  switch (i % 6) {
    case 0:
      r = v;
      g = t;
      b = p;
      break;
    case 1:
      r = q;
      g = v;
      b = p;
      break;
    case 2:
      r = p;
      g = v;
      b = t;
      break;
    case 3:
      r = p;
      g = q;
      b = v;
      break;
    case 4:
      r = t;
      g = p;
      b = v;
      break;
    case 5:
      r = v;
      g = p;
      b = q;
      break;
    default:
  }
  return `rgba(${Math.round(r * 255)}, ${Math.round(g * 255)}, ${Math.round(
    b * 255
  )}, ${a})`;
}

export const getRandomRgb = (length) => {
  const golden_ratio_conjugate = 0.618033988749895;
  const colors = [
    HsvaToRgba(1 / 3, 0.5, 0.75, 1),
    HsvaToRgba(0, 0.7, 0.9, 1),
    HsvaToRgba(2 / 3, 0.4, 0.8, 1),
    HsvaToRgba(30 / 360, 0.7, 1, 1),
    HsvaToRgba(320 / 360, 0.9, 0.78, 1),
    HsvaToRgba(0, 0, 0.36, 1),
    HsvaToRgba(176 / 360, 0.83, 0.71, 1)
  ];
  let h = Math.random();
  if (colors.length < length) {
    const iterateLimit = length - colors.length;
    for (let i = 0; i < iterateLimit; i++) {
      h += golden_ratio_conjugate;
      h %= 1;
      colors.push(HsvaToRgba(h, 0.58, 0.63, 1));
    }
  }

  return colors;
};

export const validValue = (condition) => {
  try {
    const _condition = condition();
    if (_condition !== null && _condition !== undefined && _condition !== {}) {
      return _condition;
    } else {
      return null;
    }
  } catch (_) {
    return null;
  }
};

export function downloadURI(arrayBuffer, name) {
  const blob = new Blob([arrayBuffer], {
    type: "application/pdf"
  });
  const fileURL = URL.createObjectURL(blob);
  let link = document.createElement("a");
  link.download = name;
  link.href = fileURL;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

export async function fakeApiCall({
  data = {
    status: true
  },
  timeOutMs = 3000,
  success = false
}) {
  await new Promise((resolve, reject) => {
    setTimeout(() => {
      if (success) {
        resolve(data);
      } else {
        reject(data);
      }
    }, [timeOutMs]);
  });
}
