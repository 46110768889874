import React, { useState } from "react";
import RoundedProfilePicture from "../../front-end-global-components/components/RoundedProfilePicture/RoundedProfilePicture";
import backIcon from "../../front-end-global-components/assets/Back.svg";
import { ArrowIcon } from "../../front-end-global-components/assets/assets";
import "./ConsentHistory.css";
import { dateAndTimeConverter } from "../../front-end-global-components/utils/constants";
import { isValidObject } from "../../utils/validators";
import fallback from "../../assets/icons/fallback.svg";

export default function ConsentHistory(props) {
  const [selectedConsent, setSelectedConsent] = useState(null);
  const [showAllData, setShowAllData] = useState(3);

  const data = [
    {
      title: "CONSENT ARTIFACT ID",
      value:
        selectedConsent &&
        props.abdm?.consumerConsentRequests[selectedConsent]?.consentDetail
          ?.consentId
          ? props.abdm?.consumerConsentRequests[selectedConsent]?.consentDetail
              ?.consentId
          : "Nill"
    },
    {
      title: "STATUS",
      value:
        selectedConsent &&
        props.abdm?.consumerConsentRequests[selectedConsent]?.status
          ? props.abdm?.consumerConsentRequests[selectedConsent]?.status
          : "Nill"
    },
    {
      title: "PROVIDER NAME",
      value:
        selectedConsent &&
        props.abdm?.consumerConsentRequests[selectedConsent]?.consentDetail?.hip
          ?.name
          ? props.abdm?.consumerConsentRequests[selectedConsent]?.consentDetail
              ?.hip?.name
          : "Nill"
    },
    {
      title: "DATE RANGE",
      value:
        selectedConsent &&
        props.abdm?.consumerConsentRequests[selectedConsent]?.consentDetail
          .permission.dateRange
          ? props.abdm?.consumerConsentRequests[selectedConsent]?.consentDetail
              .permission.dateRange
          : "Nill"
    },
    {
      title: "LAST UPDATED",
      value:
        selectedConsent &&
        props.abdm?.consumerConsentRequests[selectedConsent]?.lastUpdatedAt
          ? `${dateAndTimeConverter(
              props.abdm?.consumerConsentRequests[selectedConsent]
                ?.lastUpdatedAt,
              "cardDate"
            )} , ${dateAndTimeConverter(
              props.abdm?.consumerConsentRequests[selectedConsent]
                ?.lastUpdatedAt,
              "Time"
            )}`
          : "Nill"
    },
    {
      title: "CARE CONTEXT",
      value:
        selectedConsent &&
        props.abdm?.consumerConsentRequests[selectedConsent]?.consentDetail
          ?.careContexts
          ? props.abdm?.consumerConsentRequests[selectedConsent]?.consentDetail
              ?.careContexts
          : "Nill"
    }
  ];

  return (
    <div className="padding-larger inherit-parent-height ">
      {isValidObject(props.abdm.consumerConsentRequests) && (
        <div className="display-flex padding-bottom-larger  inherit-parent-width">
          {selectedConsent &&
            !props.abdm.loading &&
            isValidObject(props.abdm.consumerConsentRequests) && (
              <img
                src={backIcon}
                alt="back-arrow"
                width="auto"
                onClick={() => {
                  setSelectedConsent(null);
                }}
                className={`cursor-pointer margin-right-large ${
                  props.removeResponsive
                    ? ""
                    : "adaptive-back-arrow-row-icon-size"
                }  back-arrow-row-icon-size`}
                data-cy="secondary-header-back-arrow"
              />
            )}
          <div className="text-align-center inherit-parent-width">
            CONSENT HISTORY
          </div>
        </div>
      )}

      {!selectedConsent &&
        !props.abdm.loading &&
        isValidObject(props.abdm.consumerConsentRequests) && (
          <>
            {Object.values(props.abdm.consumerConsentRequests).map(
              (data, index) => (
                <div className="padding-bottom-large" key={index}>
                  <ConsentCard
                    cardClick={() => {
                      setSelectedConsent(data.documentId);
                    }}
                    data={data}
                  />
                </div>
              )
            )}
          </>
        )}

      {selectedConsent &&
        !props.abdm.loading &&
        isValidObject(props.abdm.consumerConsentRequests) &&
        data?.map((element, index) => (
          <div className="padding-bottom-large" key={index}>
            <div className=" white-space-no-wrap text-overflow-ellipsis">
              {element.title}
            </div>
            {element.title === "DATE RANGE" ? (
              <>
                <div className="padding-top-default">
                  From:{" "}
                  {dateAndTimeConverter(
                    +new Date(element.value.from),
                    "cardDateWithoutDay"
                  )}{" "}
                  ,{" "}
                  {dateAndTimeConverter(+new Date(element.value.from), "Time")}
                </div>
                <div className="padding-top-default">
                  To:{" "}
                  {dateAndTimeConverter(
                    +new Date(element.value.to),
                    "cardDateWithoutDay"
                  )}{" "}
                  ,{" "}
                  {dateAndTimeConverter(+new Date(element.value.from), "Time")}
                </div>
              </>
            ) : element.title === "CARE CONTEXT" ? (
              <div className="padding-top-large padding-bottom-large">
                {element.value?.slice(0, showAllData).map((data, index) => (
                  <div
                    className="padding-bottom-default white-space-no-wrap text-overflow-ellipsis"
                    key={index}
                  >
                    {data?.careContextReference}
                  </div>
                ))}
                {element.value.length > 3 && (
                  <div
                    onClick={() => {
                      if (showAllData === 3) {
                        setShowAllData(element.value.length - 1);
                      } else {
                        setShowAllData(3);
                      }
                    }}
                    className="text-align-center font-color-primary text-underline-hover cursor-pointer"
                  >
                    {showAllData === element.value.length - 1
                      ? "Show Less"
                      : "Show More"}
                  </div>
                )}
              </div>
            ) : (
              <div className=" white-space-no-wrap text-capitalize text-overflow-ellipsis padding-top-default">
                {element.value}
              </div>
            )}
          </div>
        ))}
      {!props.abdm.loading &&
        !isValidObject(props.abdm.consumerConsentRequests) && (
          <div className="inherit-parent-height flex-justify-content-center flex-align-items-center">
            <div className="display-flex flex-justify-content-center flex-direction-column flex-align-items-center">
              <img width="148px" height="120px" src={fallback} alt="" />
              <div className=" flex-justify-content-center flex-direction-column">
                <div className="text-align-center padding-top-larger font-family-gilroy-medium">
                  No consent history found
                </div>
                <div className="text-align-center padding-top-large">
                  You can view the consent history once you upload a document
                </div>
              </div>
            </div>
          </div>
        )}
      {props.abdm.loading && <Suspense />}
    </div>
  );
}

const ConsentCard = (props) => {
  return (
    <div
      onClick={props.cardClick}
      className="inherit-parent-width display-flex flex-justify-content-space-between"
    >
      <div>
        <RoundedProfilePicture size="small" />
      </div>
      <div className="display-flex flex-direction-column inherit-parent-width padding-left-large padding-right-large">
        <div className="display-flex inherit-parent-width flex-justify-content-space-between padding-bottom-medium">
          <div className="text-capitalize height-fit-content margin-top-smaller">
            {props.data.consentDetail.hip.name}
          </div>
          <div
            className={`${
              props.data.status && props.data.status === "GRANTED"
                ? "background-color-approved"
                : "background-color-denied"
            } padding-top-smaller  text-capitalize padding-bottom-smaller margin-left-small padding-left-default padding-right-default border-radius-default font-size-small height-fit-content`}
          >
            {typeof props.data.status === "string" ? props.data.status.charAt(0).toUpperCase() + props.data.status.slice(1).toLowerCase() : ""}
          </div>
        </div>
        <div className="display-flex inherit-parent-width flex-justify-content-space-between">
          <div className="font-size-small white-space-no-wrap padding-right-small">
            From:{" "}
            {dateAndTimeConverter(
              +new Date(props.data.consentDetail.permission.dateRange.from),
              "cardDateWithoutDay"
            )}
          </div>

          <div className="font-size-small white-space-no-wrap padding-left-small">
            To:{" "}
            {dateAndTimeConverter(
              +new Date(props.data.consentDetail.permission.dateRange.to),
              "cardDateWithoutDay"
            )}
          </div>
        </div>
      </div>
      <div className="display-flex flex-justify-content-center flex-align-items-center">
        <ArrowIcon />
      </div>
    </div>
  );
};

const Suspense = () => {
  return (
    <article>
      <div className="inherit-parent-width">
        {[...Array(6).keys()].map((index) => (
          <div
            className="border-bottom-2px flex-center-children-vertically"
            key={index}
          >
            <div className="shimmer inherit-parent-width padding-small margin-vertical-default border-radius-default">
              <div className="padding-default margin-default border-radius-default" />
            </div>
          </div>
        ))}
      </div>
    </article>
  );
};
