import React, { useState, useEffect, useRef } from "react";
import uploadIcon from "../../assets/icons/Upload.svg";
import Button from "../../front-end-global-components/components/Button/Button";
import "./UploadComponent.css";
import pdfIcon from "../../assets/icons/PDFICON.png";
import imageIcon from "../../assets/icons/IMAGEICON.png";
import closeIcon from "../../assets/icons/close.svg";
import UnderMaintenance from "../../front-end-global-components/components/UnderMaintenance/UnderMaintenance";
// import ChipInputBox from "../../front-end-global-components/components/ChipInputBox/ChipInputBox";
import InputBox from "../../front-end-global-components/components/InputBox/InputBox";
import NativeSelect from "../../front-end-global-components/components/NativeSelect/NativeSelect";
import CropperModal from "../../front-end-global-components/components/CropperModal/CropperModal";
import ReArrangeAndAddPrescription from "../../front-end-global-components/components/ReArrangeAndAddPrescription/ReArrangeAndAddPrescription";
import Modal from "../../front-end-global-components/components/Modal/Modal";
import { isValidArray } from "../../utils/validators";
import { setErrorStatus } from "../../redux/status/actions";

export default function UploadComponent(props) {
  const [uploadFormData, setUploadFormData] = useState({
    documentName: "",
    documentType: "",
    date: new Date().toISOString().split("T")[0],
    time: `${new Date().getHours()}:${new Date().getMinutes()}`,
    file: { type: "", src: "" },
    tags: []
  });

  const [src, setSrc] = useState([]);
  const fileInputFieldRef = useRef(null);
  const [showModal, setShowModal] = useState(false);
  const [file, setFile] = useState(null);

  const [isDateValid, setIsDateValid] = useState(true);
  const [isTimeValid, setIsTimeValid] = useState(true);

  // const onOutOfFocus = (event) => {
  //   setTimeout(() => {
  //     setActiveElement(event.target.name);
  //   }, 100);
  // };

  const addDocumentBoxOnChange = (event) => {
    if (event.target.name === "documentName") {
      return setUploadFormData({
        ...uploadFormData,
        documentName: event.target.value
      });
    }

    if (event.target.name === "documentType") {
      return setUploadFormData({
        ...uploadFormData,
        documentType: event.target.value
      });
    }

    if (event.target.name === "date") {
      return setUploadFormData({ ...uploadFormData, date: event.target.value });
    }

    if (event.target.name === "tags") {
      return setUploadFormData({ ...uploadFormData, tags: event.target.tags });
    }

    if (event.target.files) {
      if (
        ["application/pdf", "image/png", "image/jpeg"].includes(
          event.target.files[0]?.type
        ) === false
      ) {
        return setErrorStatus({
          code: "custom",
          message: "Kindly upload a valid File"
        });
      }
      if (
        ["image/png", "image/jpeg"].includes(event.target.files[0]?.type) ===
        true
      ) {
        setFile(URL.createObjectURL(event.target.files[0]));
      } else if (
        typeof event.target.files[0]?.size === "number" &&
        event.target.files[0].size <= 5000000
      ) {
        setUploadFormData({
          ...uploadFormData,
          file: { type: "file", src: event.target.files[0] }
        });
      } else {
        setErrorStatus({
          code: "input/file-size-exceeded",
          message: "Document size should be less than 5MB"
        });
      }
    }
  };

  const addDocumentHandler = async (event) => {
    event.preventDefault();
    if (
      uploadFormData.documentName !== "" &&
      uploadFormData.file.type !== "" &&
      uploadFormData.documentName.trim().length > 0
    ) {
      props.uploadDocument(uploadFormData);
      setUploadFormData({
        documentName: "",
        documentType: "",
        date: new Date().toISOString().split("T")[0],
        time: `${new Date().getHours()}:${new Date().getMinutes()}`,
        file: { type: "", src: "" },
        tags: []
      });
      setSrc([]);
    }
  };

  const allowedDocumentTypesToBeUploaded = () => {
    const documentsType = [
      "report",
      "prescription",
      "admission",
      "vaccine",
      "consultation",
      "vital",
      "others"

      // "receipt"
    ];

    // symptom , prescription , report , receipt , admission , insurance , vitals
    // let result = [];

    // if (
    //   abdmDocumentsStatus().status === remoteConfigStatus.NOT_AVAILABLE ||
    //   abdmDocumentsStatus().status === remoteConfigStatus.UNDER_MAINTENANCE
    // ) {
    //   result = [];
    // } else {
    //   documentsType.forEach((type) => {
    //     if (
    //       abdmDocumentsStatus()[type].status === remoteConfigStatus.ALLOW_ALL &&
    //       abdmDocumentsStatus()[type].write === true
    //     ) {
    //       result.push(type);
    //     }
    //   });
    // }
    return documentsType;
  };

  useEffect(() => {
    if (!uploadFormData.time) {
      return setIsTimeValid({
        status: false,
        message: "Please enter a valid time"
      });
    }
    const userEnteredDate = uploadFormData?.date;
    const date = userEnteredDate.split("-")[2];
    const month = userEnteredDate.split("-")[1];
    const year = userEnteredDate.split("-")[0];
    const currentDate = +new Date().getDate();
    const currentMonth = +new Date().getMonth() + 1;
    const currentYear = +new Date().getFullYear();

    const userEnteredTime = uploadFormData.time?.split(":");
    const currentHour = +new Date().getHours();
    const currentMinute = +new Date().getMinutes();
    if (
      parseInt(date) > currentDate ||
      parseInt(month) > currentMonth ||
      parseInt(year) > currentYear
    ) {
      return setIsTimeValid({
        status: false,
        message: "Please enter a valid time"
      });
    }
    if (
      parseInt(date) === currentDate &&
      parseInt(month) === currentMonth &&
      parseInt(year) === currentYear
    ) {
      if (
        parseInt(userEnteredTime[0]) > currentHour ||
        parseInt(userEnteredTime[1]) > currentMinute
      ) {
        return setIsTimeValid({
          status: false,
          message: "Please enter a valid time"
        });
      } else {
        return setIsTimeValid({
          status: true
        });
      }
    } else {
      return setIsTimeValid({
        status: true
      });
    }
  }, [uploadFormData]);

  const addZeroToTime = (time) => {
    var hours = time?.split(":")[0];
    var minutes = time?.split(":")[1];
    var finalTime = `${
      hours < 10 && !hours?.includes("0") ? "0" + hours : hours
    }:${minutes < 10 && !minutes?.includes("0") ? "0" + minutes : minutes}`;
    return finalTime;
  };
  return (
    <section className="padding-larger inherit-parent-height">
      {allowedDocumentTypesToBeUploaded().length < 0 ? (
        <UnderMaintenance className="inherit-parent-height" />
      ) : (
        <form
          onChange={(event) => addDocumentBoxOnChange(event)}
          onSubmit={(event) => {
            addDocumentHandler(event);
          }}
        >
          <div>
            <InputBox
              removeResponsive={true}
              className="inherit-parent-width border-radius-default"
              name="documentName"
              value={uploadFormData?.documentName}
              data-cy="documentName"
              label="Document name"
              type="text"
              required={true}
            />

            <NativeSelect
              labelClassName="letter-spacing-4-percentage"
              selectClassName="text-transform-capitalize padding-top-large "
              optionsClassName="text-transform-capitalize"
              name="documentType"
              label="Document type"
              removeResponsive={true}
              data-cy="documentType"
              required
              defaultValue={uploadFormData?.documentType}
              options={["", ...allowedDocumentTypesToBeUploaded()]}
            />

            <InputBox
              removeResponsive={true}
              label="Date of issue"
              name="date"
              type="date"
              data-cy="vitals-date"
              className="inherit-parent-width max-width-300px border-radius-default"
              isValidInput={(isValid) => {
                setIsDateValid(isValid);
              }}
              value={uploadFormData?.date}
              required={true}
            />
            <InputBox
              removeResponsive={true}
              label="Time"
              name="time"
              type="time"
              data-cy="vitals-time"
              className="padding-bottom-large"
              value={addZeroToTime(uploadFormData?.time)}
              validation={() => {
                return isTimeValid;
              }}
              onChange={(event) => {
                setUploadFormData({
                  ...uploadFormData,
                  time: event.target.value
                });
              }}
              required={true}
            />

            {/* <ChipInputBox
              name="tags"
              className="padding-bottom-larger"
              removeResponsive={true}
              label="Tags"
              notes="Type and press Enter to Add allergies"
              chips={uploadFormData?.tags}
              loading={
                props.loading === true && activeElement === "allergies"
                  ? true
                  : false
              }
              onChipAdded={(chips) => {
                setUploadFormData({ ...uploadFormData, tags: [...chips] });
                onOutOfFocus({
                  target: {
                    name: "tags",
                    value: [...chips]
                  }
                });
              }}
              onOutOfFocus={(chips) => {
                setUploadFormData({ ...uploadFormData, tags: [...chips] });
                onOutOfFocus({
                  target: {
                    name: "tags",
                    value: [...chips]
                  }
                });
              }}
            /> */}

            {uploadFormData.file.type !== "" && (
              <DocumentUploadWithFile
                className="margin-top-large"
                componentType="uploadPreview"
                cancelOnClick={() => {
                  setUploadFormData({
                    ...uploadFormData,
                    file: { type: "", src: "" }
                  });
                }}
                data={uploadFormData?.file}
              />
            )}
          </div>
          {uploadFormData.file.type === "" && (
            <div>
              <DocumentUploadWithOutFile
                className="margin-top-large"
                componentType="uploadSelect"
                inputName="uploadDocument"
              />
            </div>
          )}

          <div className=" inherit-parent-width display-flex flex-center-children-horizontally">
            <div className="inherit-parent-width max-width-150px max-height-50px padding-top-default">
              <Button
                data-cy="add-document-upload-button"
                boxShadow={false}
                className="max-width-150px"
                text="Upload"
                type="submit"
                loading={props.documents.loading}
                disabled={
                  uploadFormData.file.type !== "" &&
                  uploadFormData.documentType &&
                  uploadFormData.tags !== [] &&
                  typeof uploadFormData.documentName === "string" &&
                  uploadFormData.documentName.trim().length !== 0 &&
                  isDateValid &&
                  isTimeValid?.status
                    ? false
                    : true
                }
              />
            </div>
          </div>
        </form>
      )}

      <Modal
        show={file && file !== null}
        canIgnore={true}
        onClose={() => {
          setShowModal(!showModal);
          setFile("");
        }}
        width="inherit-parent-width"
        maxWidth="max-width-800px"
        background="false"
        boxShadow="false"
        borderRadius="false"
        height="height-90-percent"
      >
        <div
          data-cy="cropper-modal"
          className={`background-white inherit-parent-height ${"border-radius-default"} box-shadow-default font-family-gilroy-regular font-color-secondary`}
        >
          <CropperModal
            className=" border-radius-default"
            aspectRatio={1}
            OnBlobCreate={(blob) => {
              const imageFile = new File([blob], `${+new Date()}.png`, {
                type: "image/png"
              });
              setSrc([...src, imageFile]);
              setShowModal(true);
            }}
            file={file}
            setFile={() => {
              setFile("");
            }}
          />
        </div>
      </Modal>

      <Modal
        show={isValidArray(src) && src && showModal && !file}
        canIgnore={true}
        onClose={() => {
          setShowModal(!showModal);
          setSrc([]);
        }}
        width="inherit-parent-width"
        maxWidth="max-width-800px"
        background="false"
        boxShadow="false"
        borderRadius="false"
        height="height-90-percent"
      >
        <div
          data-cy="upload-modal"
          className={`background-white inherit-parent-height padding-top-larger  ${"border-radius-default"} box-shadow-default font-family-gilroy-regular font-color-secondary`}
        >
          <ReArrangeAndAddPrescription
            src={src}
            setSrc={setSrc}
            onAddExtraImageClick={() => {
              fileInputFieldRef.current.click();
            }}
            uploadButtonText="Upload Document"
            fileName={`${+new Date()}`}
            onAddPrescriptionClick={(arrayList) => {
              setUploadFormData({
                ...uploadFormData,
                file: { type: "image", src: arrayList }
              });

              // props.uploadFile({ type: "image", file: arrayList });
              setShowModal(!showModal);
            }}
            // isLoading={props.documents.loading}
          />
        </div>
      </Modal>
      <input
        ref={fileInputFieldRef}
        type="file"
        accept="image/jpeg,image/png"
        name="fileUploaderInput"
        label="inputFile"
        onClick={(event) => {
          event.target.value = "";
        }}
        className="display-none"
        onChange={(event) => {
          addDocumentBoxOnChange(event);
        }}
      />
    </section>
  );
}

function DocumentUploadWithOutFile(props) {
  return (
    <div
      className={`inherit-parent-width font-family-gilroy-medium margin-bottom-larger ${
        props.className ? props.className : ""
      }`}
    >
      <div>
        <label>
          <input
            type="file"
            accept=".pdf,.jpeg,.png"
            name={props.inputName}
            onClick={(event) => {
              event.target.value = "";
            }}
            className="display-none"
          />
          <div className="inherit-parent-width height-180px cursor-pointer flex-direction-column border-radius-default flex-center-children-horizontally flex-column border-file-upload">
            <div className="flex-justify-content-center ">
              <img draggable="false" src={uploadIcon} alt="uploadIcon" />
            </div>
            {/* <div className="inherit-parent-width flex-justify-content-center padding-top-large font-family-gilroy-medium font-color-secondary flex-center-children-vertically">
              <div className="inherit-parent-width text-align-center">
                Drop the file here or
              </div>
            </div> */}
            <div className="inherit-parent-width padding-top-large flex-justify-content-center">
              <div className="width-70px border-radius-default background-color-grey text-align-center padding-default cursor-pointer font-family-gilroy-medium font-size-medium">
                Browse
              </div>
            </div>
          </div>
        </label>
      </div>
    </div>
  );
}

function DocumentUploadWithFile(props) {
  return (
    <div
      className={`inherit-parent-width font-family-gilroy-medium margin-bottom-larger position-relative ${
        props.className ? props.className : ""
      }`}
    >
      <div className="inherit-parent-width height-180px border-radius-default background-white box-shadow-default ">
        <div className="padding-right-larger padding-left-large white-space-nowrap">
          <div className="font-color-tertiary font-size-small padding-top-default padding-bottom-small">
            Title
          </div>
          <div className="font-color-secondary font-size-medium padding-bottom-default text-overflow-ellipsis">
            {props.data.src?.name ? props.data.src?.name : +new Date() + ".png"}
          </div>
        </div>
        <div className="inherit-parent-width padding-left-default padding-right-default padding-bottom-default">
          <div className="inherit-parent-height border-radius-default flex-center-children-horizontally flex-center-children-vertically height-120px background-color-grey ">
            <img
              draggable="false"
              className="height-60px width-48px object-fit-contain"
              src={props.data.type === "file" ? pdfIcon : imageIcon}
              alt="pdf icon"
            />
          </div>
        </div>
      </div>
      <div
        className="absolute-center-self-horizontally"
        style={{ bottom: "-20px" }}
      >
        <div
          className="height-width-48px box-shadow-default border-radius-50-percent flex-center-children-vertically flex-center-children-horizontally background-white"
          onClick={props.cancelOnClick}
        >
          <div className=" background-white padding-bottom-small">
            <div className="height-width-12px">
              <img draggable="false" src={closeIcon} alt="close" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
