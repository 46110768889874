import { all, put, takeEvery } from "@redux-saga/core/effects";
import { throwError } from "../../services/error";
import { isValidString } from "../../utils/validators";
import { setErrorStatus } from "../status/actions";
import { getConsumerConsents } from "../../services/database";
import store from "../store/store";

export const abdmActionTypes = {
  PUT_ABDM_HIU_ID: "PUT_ABDM_HIU_ID",
  SET_SUBSCRIPTIONS: "SET_SUBSCRIPTIONS",
  GET_CONSUMER_CONSENT: "GET_CONSUMER_CONSENT"
};

function* putAbdmHiuIdWorker(action) {
  try {
    if (!isValidString(action.payload.hiuId)) {
      throw throwError("custom", "Please provide your HIU ID");
    }

    yield setABDMLoading(true);

    yield put({
      type: "SET_ABDM_HIU_ID",
      payload: {
        hiuId: action.payload.hiuId
      }
    });

    yield setABDMLoading(false);
  } catch (error) {
    setErrorStatus(error);
    yield setABDMLoading(false);
  }
}

function* setSubscriptionWorker(action) {
  try {
    yield setABDMLoading(true);

    yield put({
      type: "SET_SUBSCRIPTION",
      payload: {
        data: action.payload.data
      }
    });

    yield setABDMLoading(false);
  } catch (error) {
    setErrorStatus(error);
    yield setABDMLoading(false);
  }
}

function* getConsumerConsentWorker(action) {
  try {
    yield setABDMLoading(true);

    const consumerConsentRequests = yield getConsumerConsents(
      store.getState().connection.currentConnection,
      store.getState().abdmPatients.searchResults[
        store.getState().abdmPatients.selectedPatient
      ].healthId
    );

    yield put({
      type: "SET_CONSUMER_CONSENT_REQUEST",
      payload: {
        data: consumerConsentRequests
      }
    });

    yield setABDMLoading(false);
  } catch (error) {
    setErrorStatus(error);
    yield setABDMLoading(false);
  }
}

export function* abdmWatcher() {
  yield all([takeEvery("PUT_ABDM_HIU_ID", putAbdmHiuIdWorker)]);
  yield all([takeEvery("SET_SUBSCRIPTIONS", setSubscriptionWorker)]);
  yield all([takeEvery("GET_CONSUMER_CONSENT", getConsumerConsentWorker)]);
}

function* setABDMLoading(bool) {
  yield put({
    type: "SET_ABDM_LOADING",
    payload: {
      loading: bool
    }
  });
}
