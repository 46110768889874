import { all, put, takeEvery } from "@redux-saga/core/effects";
import {
  findPatientWithHealthIdOrPhoneNumber,
  sendConsentRequestToPatient,
  sendSubscriptionRequestToPatient
} from "../../services/api";
import {
  createPatientDemographicInDatabase,
  isConsentRequestPresent,
  isPatientConnectedThroughAbdm
} from "../../services/database";
import { throwError } from "../../services/error";
import { isValidArray, isValidObject } from "../../utils/validators";
import { showSecondary, showTertiary } from "../navigation/actions";
import { setErrorStatus, setSuccessStatus } from "../status/actions";
import store from "../store/store";
import {
  checkIfConsentRequestSentToAbdm,
  findPatient,
  setAbdmSelectedPatients
} from "./actions";
import { convertTimestampToDate } from "../../front-end-global-components/utils/constants";

export const abdmPatientsActionTypes = {
  FIND_PATIENT: "FIND_PATIENT",
  PUT_ABDM_SELECTED_PATIENT: "PUT_ABDM_SELECTED_PATIENT",
  SEND_SUBSCRIPTION_REQUEST_TO_PATIENT: "SEND_SUBSCRIPTION_REQUEST_TO_PATIENT",
  SEND_CONSENT_REQUEST_TO_PATIENT: "SEND_CONSENT_REQUEST_TO_PATIENT",
  CHECK_IF_CONSENT_REQUEST_SENT_TO_ABDM:
    "CHECK_IF_CONSENT_REQUEST_SENT_TO_ABDM",
  CHECK_IF_PATIENT_CONNECTED_TO_ABDM: "CHECK_IF_PATIENT_CONNECTED_TO_ABDM",
  REMOVE_ABDM_PATIENT_SEARCH_DATA: "REMOVE_ABDM_PATIENT_SEARCH_DATA",
  ADD_PATIENT_DEMOGRAPHICS: "ADD_PATIENT_DEMOGRAPHICS"
};

/**
 * used to find patient using number or health id
 * @param {any} action
 */
function* findPatientWorker(action) {
  try {
    yield setAbdmPatientsLoading(true);
    if (typeof action.payload.searchKey !== "string") {
      throw throwError("custom", "Please provide search key");
    }

    const clinicUid = store.getState().connection.currentConnection;

    yield put({
      type: "SET_ABDM_SEARCHED_PATIENT_SEARCH_KEY",
      payload: {
        searchKey: action.payload.searchKey
      }
    });
    showSecondary(null);
    showTertiary(null);

    const findPatientWithHealthIdOrPhoneNumberResponse =
      yield findPatientWithHealthIdOrPhoneNumber(
        action.payload.searchKey.includes("@")
          ? { healthId: action.payload.searchKey }
          : action.payload.searchKey.includes("+91")
          ? { phoneNumber: action.payload.searchKey }
          : { patientId: action.payload.searchKey },
        clinicUid,
        store.getState().auth.data.accessToken
      );

    if (findPatientWithHealthIdOrPhoneNumberResponse?.success) {
      if (
        !isValidArray(
          findPatientWithHealthIdOrPhoneNumberResponse.data.findResults
        )
      ) {
        throw throwError("custom", "User not found");
      } else {
        let searchResults = {};

        findPatientWithHealthIdOrPhoneNumberResponse?.data?.findResults?.forEach(
          (element) => {
            searchResults[element?.patientId] = {
              ...element
            };
          }
        );
        yield put({
          type: "SET_ABDM_PATIENT_SEARCH_RESULTS",
          payload: {
            data: searchResults
          }
        });
        if (action.payload.preSelectResult) {
          setAbdmSelectedPatients(action.payload.preSelectResult);
        }
      }
    } else {
      throw throwError("custom", "Invalid ABHA Address");
    }

    yield setAbdmPatientsLoading(false);
  } catch (error) {
    setErrorStatus(error);
    yield setAbdmPatientsLoading(false);
  }
}

function* setSelectedPatientWorker(action) {
  try {
    if (typeof action.payload.patientId !== "string") {
      throw throwError("custom", "Unable to find Patient");
    }

    if (
      !store.getState().abdmPatients.searchResults ||
      !Object.keys(store.getState().abdmPatients.searchResults).includes(
        action.payload.patientId
      )
    ) {
      throw throwError("custom", "Unable to find Patient");
    }

    yield setAbdmPatientsLoading(true);

    yield put({
      type: "SET_ABDM_SELECTED_PATIENT",
      payload: {
        selectedPatient: action.payload.patientId
      }
    });

    yield setAbdmPatientsLoading(false);

    showSecondary("selectedPatient");
    showTertiary(null);
  } catch (error) {
    setErrorStatus(error);
    yield setAbdmPatientsLoading(false);
  }
}

function* checkIfPatientConnectedWithClinicThroughAbdm(action) {
  try {
    if (!action?.payload?.removeLoading) {
      yield setABDMLoading(true);
    }
    const selectedPatientData =
      store.getState().abdmPatients.searchResults[
        store.getState().abdmPatients?.selectedPatient
      ];
    if (!isValidObject(selectedPatientData)) {
      throw throwError("custom", "Please select a profile");
    }
    const clinicId = store.getState().connection.currentConnection;
    let isPatientConnectedWithAbdm;

    if (selectedPatientData.healthId) {
      isPatientConnectedWithAbdm = yield isPatientConnectedThroughAbdm({
        patientId: selectedPatientData.patientId,
        clinicId: clinicId
      });

      yield put({
        type: "EDIT_ABDM_PATIENT_SEARCH_RESULTS",
        payload: {
          data: {
            [selectedPatientData?.patientId]: {
              ...selectedPatientData,
              isPatientConnectedWithAbdm: isPatientConnectedWithAbdm
            }
          }
        }
      });
    }
    if (!action?.payload?.removeLoading) {
      yield setABDMLoading(false);
    }
  } catch (error) {
    setErrorStatus(error);
  }
}

function* checkIfConsentRequestToAbdm(action) {
  try {
    if (!action?.payload?.removeLoading) {
      yield setABDMLoading(true);
    }
    const selectedPatientData =
      store.getState().abdmPatients.searchResults[
        store.getState().abdmPatients?.selectedPatient
      ];
    if (!isValidObject(selectedPatientData)) {
      throw throwError("custom", "Please select a profile");
    }
    const clinicId = store.getState().connection.currentConnection;
    let status;

    if (selectedPatientData.healthId) {
      const response = yield isConsentRequestPresent({
        healthId: selectedPatientData.healthId,
        clinicId: clinicId
      });

      if (isValidObject(response)) {
        if (
          isValidObject(
            Object.values(response).find((data) => data.status === "SENT")
          )
        ) {
          status = "SENT";
        } else if (
          Object.values(response).every((data) => data.status === "GRANTED")
        ) {
          status = "GRANTED";
        }

        yield put({
          type: "SET_CONSENT_REQUEST",
          payload: {
            data: response
          }
        });
      } else {
        status = null;
      }

      yield put({
        type: "EDIT_ABDM_PATIENT_SEARCH_RESULTS",
        payload: {
          data: {
            [selectedPatientData?.patientId]: {
              ...selectedPatientData,
              consentRequestStatus: status
            }
          }
        }
      });
    }
    if (!action?.payload?.removeLoading) {
      yield setABDMLoading(false);
    }
  } catch (error) {
    setErrorStatus(error);
  }
}

function* addPatientsDemographicWorker(action) {
  try {
    if (!action.payload.fullName) {
      throw throwError("custom", "Missing full name");
    }
    if (!action.payload.gender) {
      throw throwError("custom", "Missing gender");
    }
    if (!action.payload.phoneNumber) {
      throw throwError("custom", "Missing phone number");
    }

    const currentClinicId = store.getState().connection.currentConnection;
    const { dateOfBirth, fullName, gender, phoneNumber } = action.payload;
    const patientDemographics = yield createPatientDemographicInDatabase({
      dob: convertTimestampToDate(dateOfBirth),
      name: fullName,
      gender: gender.charAt(0).toUpperCase(),
      phoneNumber: phoneNumber,
      createdBy: currentClinicId
    });
    setSuccessStatus("Patient profile successfully created");
    findPatient(patientDemographics.id, patientDemographics.id);
  } catch (error) {
    setErrorStatus(error);
  }
}

function* sendSubscriptionRequestToPatientWorker(action) {
  try {
    yield setAbdmPatientsLoading(true);
    const clinicUid = store.getState().connection.currentConnection;
    const patientHealthId =
      store.getState().abdmPatients.selectedPatient.healthId;
    const purpose = { text: "Care Management", code: "CAREMGT" };
    const currentDate = new Date();
    const sixMonthAfter = new Date().setMonth(new Date().getMonth() + 6);
    const period = {
      from: currentDate.toISOString(),
      to: new Date(sixMonthAfter).toISOString()
    };
    const selectedPatientData =
      store.getState().abdmPatients.searchResults[
        store.getState().abdmPatients?.selectedPatient
      ];
    const accessToken = store.getState().auth.data.accessToken;

    let response;
    if (patientHealthId) {
      response = yield sendSubscriptionRequestToPatient(
        accessToken,
        clinicUid,
        patientHealthId,
        purpose,
        period
      );
    }
    if (
      response?.success === true &&
      response?.data?.message === "Successfully sent subscription request"
    ) {
      yield put({
        type: "EDIT_ABDM_PATIENT_SEARCH_RESULTS",
        payload: {
          data: {
            [selectedPatientData?.patientId]: {
              ...selectedPatientData,
              isPatientSubscriptionRequestSent: true
            }
          }
        }
      });
      setSuccessStatus("Successfully sent subscription request");
    }
    yield setAbdmPatientsLoading(false);
  } catch (error) {
    yield setAbdmPatientsLoading(false);
    setErrorStatus(error);
  }
}

function* sendConsentRequestToPatientWorker(action) {
  try {
    yield setABDMLoading(true);

    const clinicId = store.getState().connection.currentConnection;
    const patientId =
      store.getState().abdmPatients.searchResults[
        store.getState().abdmPatients.selectedPatient
      ].patientId;
    // const selectedSubscription = store
    //   .getState()
    //   .abdm.subscriptions.find(
    //     (data) => data.subscription.patient.id === patientHealthId
    //   );
    // const subscriptionId = selectedSubscription.documentId;
    const currentDate = new Date(+new Date() + 86400000);
    const sixMonthBefore = new Date().setMonth(new Date().getMonth() - 6);
    const dateRange = action.payload?.dataRange
      ? action.payload?.dataRange
      : {
          to: currentDate.toISOString(),
          from: new Date(sixMonthBefore).toISOString()
        };
    const clinicName =
      store.getState().connection.data[
        store.getState().connection.currentConnection
      ].companyName;
    const selectedPatientData =
      store.getState().abdmPatients.searchResults[
        store.getState().abdmPatients?.selectedPatient
      ];
    const accessToken = store.getState().auth.data.accessToken;
    let response;
    if (patientId) {
      response = yield sendConsentRequestToPatient(
        accessToken,
        clinicId,
        patientId,
        // subscriptionId,
        clinicName,
        dateRange
      );
    }
    if (response?.success === true) {
      yield put({
        type: "EDIT_ABDM_PATIENT_SEARCH_RESULTS",
        payload: {
          data: {
            [selectedPatientData?.patientId]: {
              ...selectedPatientData,
              consentRequestStatus: "SENT"
            }
          }
        }
      });
      checkIfConsentRequestSentToAbdm();
      setSuccessStatus("Consent request sent successfully");
    }
    yield setABDMLoading(false);
  } catch (error) {
    yield setABDMLoading(false);

    setErrorStatus(error);
  }
}

function* clearPatientSearchData(action) {
  yield put({
    type: "CLEAR_ABDM_PATIENT_SEARCH_DATA"
  });
}

export function* abdmPatientWorker() {
  yield all([
    takeEvery("FIND_PATIENT", findPatientWorker),
    takeEvery("PUT_ABDM_SELECTED_PATIENT", setSelectedPatientWorker),
    takeEvery(
      "SEND_SUBSCRIPTION_REQUEST_TO_PATIENT",
      sendSubscriptionRequestToPatientWorker
    ),
    takeEvery(
      "SEND_CONSENT_REQUEST_TO_PATIENT",
      sendConsentRequestToPatientWorker
    ),
    takeEvery(
      "CHECK_IF_CONSENT_REQUEST_SENT_TO_ABDM",
      checkIfConsentRequestToAbdm
    ),
    takeEvery(
      "CHECK_IF_PATIENT_CONNECTED_TO_ABDM",
      checkIfPatientConnectedWithClinicThroughAbdm
    ),
    takeEvery("ADD_PATIENT_DEMOGRAPHICS", addPatientsDemographicWorker),
    takeEvery("REMOVE_ABDM_PATIENT_SEARCH_DATA", clearPatientSearchData)
  ]);
}

function* setAbdmPatientsLoading(loadingState) {
  yield put({
    type: "SET_ABDM_PATIENTS_LOADING",
    payload: {
      loading: loadingState
    }
  });
}

function* setABDMLoading(bool) {
  yield put({
    type: "SET_ABDM_LOADING",
    payload: {
      loading: bool
    }
  });
}
