import {
  doc,
  setDoc,
  getDocs,
  getDoc,
  collectionGroup,
  addDoc,
  updateDoc,
  query,
  where,
  orderBy,
  collection,
  onSnapshot,
  writeBatch,
  limit,
  startAfter
} from "firebase/firestore";
import { useState, useEffect } from "react";
import { getCurrentUser } from "./authentication";
import store from "../redux/store/store";
import { bucketNames } from "./storage";
import { isValidArray, isValidObject } from "../utils/validators";
import { database } from "./firebase";
import { throwError } from "./error";
import { setErrorStatus } from "../redux/status/actions";
import { setAccountProfiles } from "../redux/profile/actions";
import { setSubscriptions } from "../redux/abdm/actions";
import { setUploadHistory } from "../redux/history/actions";
import { getBrandData, setConnections } from "../redux/connection/actions";
import { setNotifications } from "../redux/notification/actions";
import { logout } from "../redux/authentication/actions";
import { shard } from "../utils/constants";

export async function createProfile(userName) {
  const batch = writeBatch(database);
  const currentUser = getCurrentUser();
  const rootRef = doc(collection(database, "clinicStaffs"));
  batch.set(rootRef, {
    phoneNumber: currentUser.phoneNumber,
    uid: currentUser.uid,
    fullName: userName
  });
  batch.set(
    doc(database, "clinicStaffs", rootRef.id, "clinicStaffsPublic", "profile"),
    {
      phoneNumber: currentUser.phoneNumber,
      uid: currentUser.uid,
      fullName: userName,
      profileId: rootRef.id
    }
  );
  batch.set(
    doc(database, "clinicStaffs", rootRef.id, "clinicStaffsPrivate", "profile"),
    {
      fullName: userName,
      phoneNumber: currentUser.phoneNumber,
      uid: currentUser.uid,
      profileId: rootRef.id
    }
  );
  await batch.commit();
}

export async function editProfile(data, profileId, connectionIds) {
  const rootRef = doc(database, "clinicStaffs", profileId);
  if (data.userName.includes("+91")) {
    throw throwError("custom", "Name is Not valid from edit profile");
  }
  await updateDoc(rootRef, {
    ...(data.userName ? { fullName: data.userName } : {})
  });

  const privateRef = doc(
    database,
    "clinicStaffs",
    profileId,
    "clinicStaffsPrivate",
    "profile"
  );

  await updateDoc(privateRef, {
    ...(data.userName ? { fullName: data.userName } : {})
  });

  if (isValidArray(connectionIds)) {
    connectionIds.forEach((element) => {
      const connectionsRef = doc(
        database,
        "clinicStaffs",
        profileId,
        "connections",
        element
      );
      updateDoc(connectionsRef, {
        ...(data.userName ? { fullName: data.userName } : {})
      });
    });
  }
}

export async function createClinicProfile(data, brandId) {
  const { id: documentId } = await addDoc(collection(database, "clinics"), {
    escalationContact: {
      fullName: "",
      phoneNumber: "",
      email: ""
    },
    brandId: brandId
  });
  const publicRef = doc(
    database,
    "clinics",
    documentId,
    "clinicsPublic",
    "profile"
  );
  await setDoc(
    publicRef,
    {
      ...(data.companyName ? { clinicName: data.companyName } : {}),
      ...(data.locality ? { locality: data.locality } : {}),
      ...(data.phoneNumber ? { phoneNumber: data.phoneNumber } : {}),
      ...(data.email ? { email: data.email } : {}),
      ...(data.address ? { address: data.address } : {}),
      ...(data.website ? { website: data.website } : {}),
      ...(data.city ? { city: data.city } : {}),
      ...(data.state ? { state: data.state } : {}),
      ...(data.pincode ? { pincode: data.pincode } : {}),
      brandId: brandId,
      clinicId: documentId
    },
    { merge: true }
  );
  const privateRef = doc(
    database,
    "clinics",
    documentId,
    "clinicsPrivate",
    "profile"
  );
  await setDoc(
    privateRef,
    {
      connectedPhoneNumbers: [],
      brandId: brandId,
      clinicId: documentId
    },
    { merge: true }
  );

  return documentId;
}

export async function addConnectionsToProfile(
  documentId,
  data,
  profileId,
  brandId
) {
  const currentUser = getCurrentUser();
  const connectionsRef = doc(
    database,
    "clinicStaffs",
    profileId,
    "connections",
    documentId
  );
  await setDoc(
    connectionsRef,
    {
      connectedAt: +new Date(),
      ...(data.companyName ? { companyName: data.companyName } : {}),
      ...(data.locality ? { locality: data.locality } : {}),
      phoneNumber: currentUser.phoneNumber,
      brandId: brandId,
      userType: "owner",
      profileId: profileId,
      connectionId: documentId
    },
    { merge: true }
  );
}

export async function createClinicBrand(data, profileId) {
  const currentUser = getCurrentUser();
  const { id: documentId } = await addDoc(
    collection(database, "clinicBrands"),
    {
      ...(data.companyName ? { name: data.companyName } : {}),
      ...(data.email ? { email: data.email } : {}),
      ...(data.website ? { website: data.website } : {}),
      createdBy: {
        uid: currentUser.uid,
        profileId: profileId
      }
    }
  );

  const profileRef = doc(database, "clinicBrands", documentId);
  await updateDoc(profileRef, {
    ...(data.companyLogo
      ? {
          logo: `gs://${bucketNames.nintoProfilePictures}/clinicBrands/${documentId}.png`
        }
      : {}),
    processing: true
  });
  return documentId;
}

export async function getBrandDataById(brandId) {
  const getBrandData = doc(database, "clinicBrands", brandId);
  const querySnapshot = await getDoc(getBrandData);
  return querySnapshot.data();
}

export function useProfileListener(props) {
  const [profileListener, setProfileListener] = useState({
    listener: null
  });

  const subscribeToProfile = (phoneNumber) => {
    if (phoneNumber) {
      const profileQuery = query(
        collection(database, "clinicStaffs"),
        where("phoneNumber", "==", phoneNumber)
      );

      return onSnapshot(
        profileQuery,
        // { includeMetadataChanges: true },
        (dataSnapshot) => {
          let profiles = {};
          // let isOnline = true;

          dataSnapshot.forEach((doc) => {
            profiles[doc.id] = {
              ...doc.data()
            };
          });
          setAccountProfiles(profiles);
        },
        (error) => {
          console.error(error, "from profile");
          setErrorStatus(error);
          logout();
        }
      );
    }
  };
  // profile listener
  useEffect(() => {
    if (
      props.isAuthed === true &&
      typeof props.phoneNumber === "string" &&
      typeof props.uid === "string" &&
      profileListener.listener === null
    ) {
      setProfileListener({
        listener: subscribeToProfile(props.phoneNumber)
      });
    } else if (
      props.isAuthed === false &&
      typeof props.phoneNumber !== "string" &&
      typeof props.uid !== "string" &&
      typeof profileListener.listener === "function"
    ) {
      profileListener.listener();
      setProfileListener({
        listener: null
      });
    }
    // eslint-disable-next-line
  }, [props.isAuthed, props.phoneNumber, props.uid]);
}

export async function useConnectionsListener(props) {
  const [connectionsListener, setConnectionsListener] = useState({
    listener: null
  });

  const subscribeToConnections = (phoneNumber, uid) => {
    if (phoneNumber && uid) {
      const connectionsQuery = query(
        collection(database, "clinicStaffs", uid, "connections"),
        where("phoneNumber", "==", phoneNumber)
      );
      return onSnapshot(
        connectionsQuery,
        (querySnapshot) => {
          let connections = {};
          let brandData = [];
          querySnapshot.forEach((doc) => {
            if (isValidObject(doc.data())) {
              brandData.push(doc.data().brandId);
              connections[doc.id] = { ...doc.data(), connectionId: doc.id };
            }
          });
          getBrandData(brandData);
          setConnections(connections);
        },
        (error) => {
          console.error(error, "from connection");
          setErrorStatus(error);
          logout();
        }
      );
    } else {
      return;
    }
  };

  // connection listener
  useEffect(() => {
    if (props.isAuthed === true && props.uid && props.phoneNumber) {
      setConnectionsListener({
        listener: subscribeToConnections(props.phoneNumber, props.uid)
      });
    } else if (
      props.isAuthed === false &&
      typeof connectionsListener.listener === "function"
    ) {
      connectionsListener.listener();
      setConnectionsListener({
        listener: null
      });
    }
    // eslint-disable-next-line
  }, [props.uid, props.isAuthed, props.phoneNumber]);
}

export async function useSubscriptionsListener(props) {
  const [subscriptionsListener, setSubscriptionsListener] = useState({
    listener: null
  });
  const subscribeToSubscriptions = (hfrId) => {
    if (hfrId) {
      const connectionsQuery = query(
        collection(database, "subscriptions"),
        where("subscription.hiu.id", "==", hfrId),
        where("requestedPeriod.to", ">", new Date().toISOString())
      );
      return onSnapshot(
        connectionsQuery,
        (querySnapshot) => {
          let subscriptions = [];
          querySnapshot.forEach((doc) => {
            // if (isValidObject(doc.data())) {
            subscriptions.push({ ...doc.data(), documentId: doc.id });
            // }
          });
          setSubscriptions(subscriptions);
        },
        (error) => {
          setErrorStatus(error);
          logout();
        }
      );
    } else {
      return;
    }
  };

  // subscription listener
  useEffect(() => {
    if (props.isAuthed === true && props.hfrId) {
      setSubscriptionsListener({
        listener: subscribeToSubscriptions(props.hfrId)
      });
    } else if (
      props.isAuthed === false &&
      typeof subscriptionsListener.listener === "function"
    ) {
      subscriptionsListener.listener();
      setSubscriptionsListener({
        listener: null
      });
    }
    // eslint-disable-next-line
  }, [props.isAuthed, props.hfrId]);
}

export const createQuery = async (data) => {
  await addDoc(collection(database, "queries"), data);
};

export async function setNotificationRead(notificationId, clinicId) {
  const notificationRef = doc(
    database,
    "clinicStaffs",
    clinicId,
    "notifications",
    notificationId
  );
  await updateDoc(notificationRef, {
    read: true
  });
  return { success: true };
}

export async function searchDoctors(phoneNumber) {
  const searchData = query(
    collection(database, "clinicStaffs"),
    where("phoneNumber", "==", phoneNumber)
  );
  const querySnapshot = await getDocs(searchData);
  const obj = {};
  querySnapshot.forEach((doc) => {
    obj[doc.data().uid] = {
      ...doc.data()
    };
  });
  return obj;
}

export async function searchDocuments(phoneNumber) {
  const uid = store.getState().auth.data.uid;
  const currentTime = +new Date() - 172800000;
  const searchData = query(
    collection(database, "documents"),
    where("to.phoneNumber", "==", phoneNumber),
    where("from.id", "==", uid),
    where("shard", "in", shard.value),
    where("timestamp", ">", currentTime),
    where("type", "in", ["prescription", "report", "admission", "receipt"])
  );
  const querySnapshot = await getDocs(searchData);
  const obj = {};
  querySnapshot.forEach((doc) => {
    if (doc.data().type !== "vitals") {
      obj[doc.id] = { ...doc.data(), documentId: doc.id };
    }
  });
  return obj;
}

export async function updateSortedPatients(doctorId, sortedPatients) {
  const batch = writeBatch(database);
  sortedPatients.forEach((item) => {
    const documentRef = doc(
      database,
      "clinicStaffs",
      doctorId,
      "pinnedPatients",
      item.docId
    );
    batch.update(documentRef, {
      successivePatient: item.successivePatient
    });
  });
  await batch.commit();
}

export async function unPinPinnedPatient(
  doctorId,
  toBeModifiedInDB,
  documentId
) {
  const batch = writeBatch(database);
  // Update the population of 'SF'

  if (isValidObject(toBeModifiedInDB)) {
    const toBeModifiedInDBRef = doc(
      database,
      "clinicStaffs",
      doctorId,
      "pinnedPatients",
      toBeModifiedInDB.docId
    );
    batch.update(toBeModifiedInDBRef, {
      successivePatient: toBeModifiedInDB.successivePatient
    });
  }

  // Delete the city 'LA'
  const toBeDeletedInDBRef = doc(
    database,
    "clinicStaffs",
    doctorId,
    "pinnedPatients",
    documentId
  );
  batch.delete(toBeDeletedInDBRef);
  await batch.commit();
}

export async function pinPatient(
  doctorId,
  staffId,
  toBeModifiedInDB,
  patientDataToBeAdded
) {
  const batch = writeBatch(database);

  if (isValidObject(toBeModifiedInDB)) {
    const toBeModifiedInDBRef = doc(
      database,
      "clinicStaffs",
      doctorId,
      "pinnedPatients",
      toBeModifiedInDB.docId
    );
    batch.update(toBeModifiedInDBRef, {
      successivePatient: toBeModifiedInDB.successivePatient
    });
  }

  const documentRef = doc(
    database,
    "clinicStaffs",
    doctorId,
    "pinnedPatients",
    patientDataToBeAdded.patientDemographicId
  );
  batch.set(documentRef, {
    doctorId: doctorId,
    patient: {
      patientId: patientDataToBeAdded.patientDemographicId,
      fullName: patientDataToBeAdded.fullName,
      ...(patientDataToBeAdded.phoneNumber
        ? {
            phoneNumber: patientDataToBeAdded.phoneNumber
          }
        : {}),
      ...(patientDataToBeAdded.healthId
        ? {
            healthId: patientDataToBeAdded.healthId
          }
        : {})
    },
    pinnedBy: {
      clinicId: patientDataToBeAdded.clinicId,
      staffId: staffId
    },
    permanent: false,
    pinnedAt: +new Date(),
    successivePatient: null
  });

  // const doc = await addDoc(collectionRef, {
  // cid: clinicId,
  // did: doctorId,
  // pid: patientId,
  // fullName: patientFullName,
  // permanent: false,
  // phoneNumber: patientPhoneNumber,
  // timestamp: +new Date(),
  // successivePatient: successivePatient
  // });

  // Delete the city 'LA'
  // const toBeDeletedInDBRef = doc(
  //   database,
  //   "doctors",
  //   doctorId,
  //   "pinnedPatients",
  //   documentId
  // );
  // batch.delete(toBeDeletedInDBRef);
  await batch.commit();
  return patientDataToBeAdded.patientDemographicId;
}

export async function getDoctorsPinnedPatients(doctorId, clinicId, patientId) {
  let searchData;
  if (typeof patientId !== "string") {
    searchData = query(
      collection(database, "clinicStaffs", doctorId, "pinnedPatients"),
      where("pinnedBy.clinicId", "==", clinicId),
      where("permanent", "==", false)
    );
  } else {
    searchData = query(
      collection(database, "clinicStaffs", doctorId, "pinnedPatients"),
      where("pinnedBy.clinicId", "==", clinicId),
      where("patient.patientId", "==", patientId),
      where("permanent", "==", false)
    );
  }

  const querySnapshot = await getDocs(searchData);
  const obj = {};
  querySnapshot.forEach((doc) => {
    if (isValidObject(doc.data())) {
      obj[doc.data().patient.patientId] = {
        ...doc.data(),
        docId: doc.id
      };
    }
  });

  return obj;
}

export async function searchConnectedDoctors(phoneNumber, clinicId) {
  const searchData = query(
    collectionGroup(database, "connections"),
    where("userType", "==", "doctor"),
    where("phoneNumber", "==", phoneNumber),
    where("connectionId", "==", clinicId)
  );
  const querySnapshot = await getDocs(searchData);
  const obj = {};
  querySnapshot.docs.forEach((doc) => {
    obj[doc.id] = {
      ...doc.data()
    };
  });
  return obj;
}

export async function deleteDocument(documentId, clinicId, staffId) {
  const documentRef = doc(
    database,
    "clinics",
    clinicId,
    "uploadedDocuments",
    documentId
  );
  await updateDoc(documentRef, {
    redacted: {
      by: staffId,
      timestamp: +new Date(),
      shard: ["a"],
      reason: ""
    }
  });
  return { success: true };
}

export const addVitals = async (data) => {
  const addVitalsResponse = await addDoc(
    collection(database, "documents"),
    data
  );
  return addVitalsResponse;
};

export async function getPaginatedNotifications(
  profileId,
  lastVisible,
  pageNumber,
  update
) {
  const notifications = {};
  const validDate = new Date().setHours(0, 0, 0, 0);
  const paginationNotifications = query(
    collection(database, "clinicStaffs", profileId, "notifications"),
    // where("shard", "in", shard.value),
    where("timestamp", "<=", +validDate + 86400000 * 2),
    orderBy("timestamp", "desc"),
    startAfter(lastVisible),
    limit(50)
  );

  const querySnapshot = await getDocs(paginationNotifications);
  querySnapshot.forEach((doc) => {
    if (isValidObject(doc.data())) {
      notifications[doc.id] = {
        documentId: doc.id,
        ...(update
          ? { pageNumber: pageNumber }
          : { pageNumber: pageNumber + 1 }),
        ...doc.data()
      };
    }
  });
  const lastVisibleDoc = querySnapshot.docs[querySnapshot.docs.length - 1];
  return {
    notifications: notifications,
    lastVisible: isValidObject(lastVisibleDoc) ? lastVisibleDoc : "end"
  };
}

export function useNotificationListener(props) {
  const [notificationListener, setNotificationListener] = useState({
    listener: null
  });
  const subscribeToNotification = (profileId, phoneNumber, auth) => {
    if (!profileId && !auth) {
      return;
    }

    const currentDate = +new Date().setHours(0, 0, 0, 0);
    const notificationQuery = query(
      collection(database, "clinicStaffs", profileId, "notifications"),
      where("phoneNumber", "==", phoneNumber),
      where("expiresAt.shard", "in", shard.value),
      where("timestamp", ">=", currentDate),
      orderBy("timestamp", "desc"),
      limit(50)
    );
    return onSnapshot(
      notificationQuery,
      (querySnapshot) => {
        let notifications = {};
        querySnapshot.forEach((doc) => {
          notifications[doc.id] = {
            documentId: doc.id,
            pageNumber: 0,
            ...doc.data()
          };
        });
        const lastVisible = querySnapshot.docs[querySnapshot.docs.length - 1];

        setNotifications(notifications, lastVisible, profileId);
      },
      (error) => {
        console.error("from notification listener for", profileId, error);

        setErrorStatus(error);
        logout();
      }
    );
  };

  // notification listener
  useEffect(() => {
    if (
      props.isAuthed === true &&
      typeof props.profileId === "string" &&
      typeof props.phoneNumber === "string" &&
      notificationListener.listener === null
    ) {
      setNotificationListener({
        listener: subscribeToNotification(
          props.profileId,
          props.phoneNumber,
          props.isAuthed
        )
      });
    } else if (
      (props.isAuthed === false ||
        typeof props.profileId !== "string" ||
        typeof props.phoneNumber !== "string") &&
      typeof notificationListener.listener === "function"
    ) {
      notificationListener.listener();
      setNotificationListener({
        listener: null
      });
    }
    // eslint-disable-next-line
  }, [props.isAuthed, props.profileId]);
}

export async function useDocumentListener(props) {
  const [documentsListener, setDocumentsListener] = useState({
    listener: null
  });
  const subscribeToDocuments = (clinicId, auth) => {
    if (!clinicId && !auth) {
      return;
    }
    const validDate = new Date().setHours(0, 0, 0, 0);
    const uploadHistoryDocumentsQuery = query(
      collection(database, "clinics", clinicId, "uploadedDocuments"),
      // where("shard", "in", shard.value),
      where("timestamp", "<=", +validDate + 86400000 * 2),
      orderBy("timestamp", "desc"),
      limit(50)
    );
    return onSnapshot(
      uploadHistoryDocumentsQuery,
      (querySnapshot) => {
        let documents = {};

        querySnapshot.forEach(
          (doc) => {
            if (isValidObject(doc.data())) {
              documents[doc.data().clinicId] = {
                ...documents[doc.data().clinicId],
                [doc.id]: {
                  documentId: doc.id,
                  pageNumber: 0,
                  ...doc.data()
                }
              };
            }
          },

          (error) => {
            console.error(error, "from documents");
            setErrorStatus(error);
          }
        );

        const lastVisible = querySnapshot.docs[querySnapshot.docs.length - 1];

        setUploadHistory(documents, lastVisible, clinicId);
      },
      (error) => {
        console.error("from document listener for", clinicId, error);
        setErrorStatus(error);
        logout();
      }
    );
  };

  // documents listener
  useEffect(() => {
    if (
      documentsListener.listener === null &&
      isValidArray(props.clinicId) &&
      props.isAuthed === true
    ) {
      let listener = {};
      for (const clinicId of props.clinicId) {
        if (
          isValidObject(documentsListener?.listener) &&
          documentsListener.listener[clinicId]
        ) {
          continue;
        }

        listener = {
          ...listener,
          ...{ [clinicId]: subscribeToDocuments(clinicId, props.isAuthed) }
        };
      }
      setDocumentsListener({ ...documentsListener.listener, listener });
    } else if (
      props.isAuthed === false &&
      documentsListener?.listener !== null
    ) {
      for (const keys in documentsListener.listener) {
        if (typeof documentsListener.listener[keys] === "function") {
          documentsListener.listener[keys]();
        }
      }
      setDocumentsListener({
        listener: null
      });
    }
    // eslint-disable-next-line
  }, [props.clinicId, props.isAuthed]);
}

export const createForm = async (formData) => {
  const _fields = [];
  formData.fields.forEach((data) => {
    _fields.push({
      name: data.name,
      required: data.required,
      type: data.type
    });
  });
  const doc = await addDoc(
    collection(database, "clinics", formData.uid, "templates"),
    {
      uid: formData.uid,
      phoneNumber: formData.phoneNumber,
      name: formData.name,
      fields: [..._fields],
      timestamp: +new Date()
    }
  );
  return doc.id;
};

export const updateForm = async (formData, documentId) => {
  const _fields = [];
  formData.fields.forEach((data) => {
    _fields.push({
      name: data.name,
      required: data.required,
      type: data.type
    });
  });
  const formRef = doc(
    database,
    "clinics",
    formData.uid,
    "templates",
    documentId
  );
  await updateDoc(formRef, {
    fields: [..._fields]
  });
};

export const getForms = async (phoneNumber) => {
  const clinicFormsQuery = query(
    collectionGroup(database, "templates"),
    where("phoneNumber", "==", phoneNumber)
  );
  const querySnapshot = await getDocs(clinicFormsQuery);
  const result = {};
  querySnapshot.forEach((doc) => {
    result[doc.id] = { ...doc.data(), documentId: doc.id };
  });

  return result;
};

export const uploadForm = async (formData) => {
  await addDoc(collection(database, "documents"), { ...formData });
};

export async function getPaginatedDocuments(
  clinicId,
  lastVisible,
  pageNumber,
  update
) {
  const documents = {};
  const validDate = new Date().setHours(0, 0, 0, 0);
  const paginationDocuments = query(
    collection(database, "clinics", clinicId, "uploadedDocuments"),
    // where("shard", "in", shard.value),
    where("timestamp", "<=", +validDate + 86400000 * 2),
    orderBy("timestamp", "desc"),
    startAfter(lastVisible),
    limit(50)
  );

  const querySnapshot = await getDocs(paginationDocuments);

  querySnapshot.forEach((doc) => {
    if (isValidObject(doc.data())) {
      documents[doc.id] = {
        documentId: doc.id,
        ...(update
          ? { pageNumber: pageNumber }
          : { pageNumber: pageNumber + 1 }),
        ...doc.data()
      };
    }
  });
  const lastVisibleDoc = querySnapshot.docs[querySnapshot.docs.length - 1];
  return {
    documents: documents,
    lastVisible: isValidObject(lastVisibleDoc) ? lastVisibleDoc : "end"
  };
}

export async function isPatientConnectedThroughAbdm({ patientId, clinicId }) {
  const careContextDocuments = query(
    collection(database, "clinics", clinicId, "careContexts"),
    where("patient.patientId", "==", patientId),
    where("linked", "==", true),
    where("processing", "==", false)
  );

  const careContextQuerySnapshot = await getDocs(careContextDocuments);

  if (!careContextQuerySnapshot.empty) {
    return true;
  }

  const linkingTokenDocuments = query(
    collection(database, "clinics", clinicId, "authorisations"),
    where("patient.patientId", "==", patientId),
    where("status", "==", "GRANTED")
  );

  const linkingTokenSnapshot = await getDocs(linkingTokenDocuments);
  if (!linkingTokenSnapshot.empty) {
    return true;
  }

  return false;
}

export async function isConsentRequestPresent({ healthId, clinicId }) {
  const careContextDocuments = query(
    collection(database, "clinics", clinicId, "consentRequests"),
    where("healthId", "==", healthId)
  );

  const careContextQuerySnapshot = await getDocs(careContextDocuments);

  const result = {};
  careContextQuerySnapshot.forEach((doc) => {
    result[doc.id] = { ...doc.data(), documentId: doc.id };
  });
  return result;
}

export const createPatientDemographicInDatabase = async ({
  phoneNumber,
  dob,
  name,
  gender,
  createdBy
}) => {
  const rootRef = collection(database, "patient-demographics");
  const patientDemographics = await addDoc(rootRef, {
    dob: dob,
    phoneNumber: phoneNumber,
    name: name,
    gender: gender,
    createdBy: createdBy,
    timestamp: +new Date()
  });
  return patientDemographics;
};

export async function getPatientDocuments(patientId, currentClinicId) {
  let result = [];

  const collectionQueryRef1 = query(
    collection(database, "clinics", currentClinicId, "transferredDocuments"),
    where("patient.patientId", "==", patientId)
  );
  const querySnapshot1 = await getDocs(collectionQueryRef1);
  querySnapshot1.forEach((doc) => {
    result.push({ ...doc.data(), documentId: doc.id });
  });

  return result;
}

export async function getDataTransferDetails(
  patientId,
  currentClinicId,
  consentRequestId
) {
  let result = [];

  const collectionQueryRef1 = query(
    collection(database, "clinics", currentClinicId, "dataTransfers"),
    where("patient.patientId", "==", patientId),
    where("consentRequestId", "==", consentRequestId)
  );
  const querySnapshot1 = await getDocs(collectionQueryRef1);
  querySnapshot1.forEach((doc) => {
    result.push({ ...doc.data(), documentId: doc.id });
  });

  return result;
}

export async function getClinicPublicData(currentClinicId) {
  const publicProfileData = query(
    collection(database, "clinics", currentClinicId, "clinicsPublic")
  );
  const querySnapshot = await getDocs(publicProfileData);
  let obj = {};
  querySnapshot.forEach((doc) => {
    obj = doc.data();
  });
  return obj;
}

export async function getConsumerConsents(currentClinicId, healthId) {
  const publicProfileData = query(
    collection(database, "clinics", currentClinicId, "consumerConsents"),
    where("consentDetail.patient.id", "==", healthId)
  );
  const querySnapshot = await getDocs(publicProfileData);
  let obj = {};
  querySnapshot.forEach((doc) => {
    obj[doc.id] = { ...doc.data(), documentId: doc.id };
  });
  return obj;
}

export async function updateClinicPublicData(
  currentClinicId,
  publicProfileData
) {
  const publicProfileDocRef = doc(
    database,
    "clinics",
    currentClinicId,
    "clinicsPublic",
    "profile"
  );
  await updateDoc(publicProfileDocRef, publicProfileData);
}

export async function updatePersonalProfileData(
  profileId,
  personalProfileData
) {
  const publicProfileDocRef = doc(database, "clinicStaffs", profileId);
  await updateDoc(publicProfileDocRef, personalProfileData);
}
